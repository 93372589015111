$primary-color: #002b5a;
$on-primary-color: #f5f5f5;
$secondary-color: #ffb14a;
$button-hover-color: #ff9100;
$lato: 'Lato', sans-serif;
$alexBrush: 'Alex Brush', cursive;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: 0.2s linear;
    font-family: $lato;
}

html {
    font-size: 100%;
    max-width: 1440px;
    margin: 0 auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

@mixin cardParam($flex-direction) {
    background-color: #fff;
    box-shadow: 0 3px 35px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: $flex-direction;
}

@mixin textParam($text-color, $font-family, $font-size, $line-height, $font-weight) {
    color: $text-color;
    font-family: $font-family;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
}

@mixin buttonParam($text-color, $background-color) {
    font-weight: bold;
    border: 0;
    color: $text-color;
    background-color: $background-color;
    // margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 16px 32px;
    border-radius: 50px;
    font-size: 18px;
}

@mixin dashParam {
    width: 80px;
    height: 0;
    border: 2px solid $primary-color;
    border-radius: 10px;
}