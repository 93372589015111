.arrLeft {
  width: 72px;
  height: 72px;
  background-color: #ffb14a;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 80px;
}

.arrRight {
  width: 72px;
  height: 72px;
  background-color: #ffb14a;
  display: grid;
  place-items: center;
  cursor: pointer;
  left: calc(50% - 72px);
}

.icon {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 980px) {
  .arrLeft {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 760px) {
  .arrLeft {
    width: 30px;
    height: 30px;
    bottom: 20rem;
    left: 30px;
  }

  .arrRight {
    width: 30px;
    height: 30px;
    bottom: 20rem;
    left: calc(100% - 70px);
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}

@media only screen and (max-width: 520px) {
  .arrLeft {
    width: 30px;
    height: 30px;
    bottom: 15rem;
    margin-left: 30px;
  }

  .arrRight {
    width: 30px;
    height: 30px;
    bottom: 15rem;
    left: calc(100% - 60px);
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}
