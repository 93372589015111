.carousel {
  overflow: hidden;
  position: relative;
  /* margin: 80px; */
}

.inner {
  white-space: nowrap;
  transition: 0.4s linear;
}

.carousel-item {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

.ci {
  justify-content: center;
  flex-direction: row;
}

.indicators {
  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: -3.8rem;
  left: 23rem;
  bottom: 10;
}

.indicators button {
  margin-left: 4px;
  background: #a2b9d1;
  width: 11px;
  height: 11px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
}

.indicators button.active {
  background: #ffb14a;
}

.arrows {
  display: flex;
}

.left {
  width: 72px;
  height: 72px;
  background-color: #ffb14a;
  margin-right: 24px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.right {
  width: 72px;
  height: 72px;
  background-color: #ffb14a;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  color: #002b5a;
}

.slideleft {
  width: 50px;
  height: 50px;
  background-color: #ffb14a;
  margin-right: 24px;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: absolute;
  top: 45%;
  left: calc(50% - 340px);
}

.slideright {
  width: 50px;
  height: 50px;
  background-color: #ffb14a;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: absolute;
  top: 45%;
  right: calc(50% - 340px);
}

.img {
  /* width: 700px; */
  height: 500px;
}

@media screen and (max-width: 768px) {
  .img {
    width: 90vw;
  }

  .slideleft {
    width: 30px;
    height: 30px;
    margin-right: 24px;
    left: 3.5vw;
  }

  .slideright {
    width: 30px;
    height: 30px;
    right: 3.5vw;
  }

  .ci {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .indicators {
    left: 110vw;
    bottom: 22.5rem;
  }

  .arrows {
    position: absolute;
    bottom: 19rem;
    left: 80vw;
  }

  .left {
    width: 30px;
    height: 30px;
    margin-right: 16px;
  }

  .right {
    width: 30px;
    height: 30px;
  }

  .icon {
    width: 6px;
    height: 12px;
  }
}

@media screen and (max-width: 600px) {
  .indicators {
    left: 110vw;
    bottom: 20.2rem;
  }

  .arrows {
    position: absolute;
    bottom: 17rem;
    left: 78vw;
  }
}
