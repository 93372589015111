.bawse__video {
  width: 70vw;
  height: 80vh;
  overflow: hidden;
}

.bawse__video iframe {
  position: absolute;
  top: 58%;
  left: 50%;
  width: 100%;
  height: 80%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 10;
}

/* Modal */

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  cursor: pointer;
  overflow: hidden;
}

.modal-content {
  transform: translateZ(-200px);
  transition: all 0.3s ease-in-out;
  position: absolute;
  /* top: 0; */
  /* height: 100%; */
  overflow: auto;
}

@media screen and (max-height: 650px) {
  .modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    cursor: pointer;
    overflow: hidden;
  }
  .bawse__video {
    width: 70vw;
    height: 100vh;
    overflow: hidden;
  }

  .bawse__video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 10;
  }
  .modal-content {
    transform: translateZ(-200px);
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    height: 100%;
    overflow: auto;
  }
}

.modal-popup-content {
  transform: translateZ(-200px);
  transition: all 0.3s ease-in-out;
  position: absolute;
  overflow: auto;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal.enter-done .modal-content {
  transform: translateZ(0);
}

.modal.exit .modal-content {
  transform: translateZ(-200px);
}
