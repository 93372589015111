@import './variables';

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     -webkit-box-shadow: 0 0 0 30px $primary-color inset !important;
//     -webkit-text-fill-color: white !important;
// }

a {
    text-decoration: none;
}

// Navbar.jsx
nav {
    width: 100%;
    background-color: $primary-color;
    color: $on-primary-color;
    height: 100px;
    padding: 0px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    top: 0px;
    font-size: 15px;

    .logo {
        width: 130px;
        height: 75px;
        object-fit: cover;
    }

    .toggle {
        display: none;
    }

    .right {
        display: flex;

        .links {
            display: flex;
            list-style: none;
            align-items: center;
            text-align: center;

            .link {
                margin-right: 50px;
                cursor: pointer;

                &.activeClass {
                    color: $secondary-color
                }

                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: $on-primary-color;

                    &.current {
                        color: $secondary-color;
                        font-weight: 700;
                    }

                    &:hover {
                        color: $secondary-color;
                        font-weight: 400;
                    }
                }


                span {
                    color: $on-primary-color;

                    &:hover {
                        padding: 40px 0;
                        color: white;
                    }

                    .icon {
                        margin-left: 7px;
                    }
                }

                ul {
                    display: none;
                }

                &:hover ul {
                    position: absolute;
                    top: 100px;
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    padding: 16px 8px;
                    width: 130px;
                    // height: 700px;
                    row-gap: 8px;
                    transition: all 3s linear;
                    border: 1px solid rgba(0, 43, 90);
                    // color: $primary-color;

                    &.long {
                        row-gap: 12px;
                        column-gap: 12px;
                        width: 350px;
                        display: grid;
                        grid-template-columns: repeat(2, auto);
                        padding: 16px 0px 16px 16px;

                    }

                    a {
                        color: $primary-color;

                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:1240px) {
    nav {
        padding: 0px 30px;

        .logo {
            width: 120px;
            height: 70px;
        }

        .right {
            .links {
                .link {
                    margin-right: 40px;
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    nav {
        padding: 0px 30px;

        .logo {
            width: 110px;
            height: 60px;
        }

        .right {
            .links {
                .link {
                    margin-right: 35px;
                }
            }
        }
    }
}

@media only screen and (max-width:991px) {
    nav {
        padding: 0px 50px;
        font-size: 17px;
        display: flex;
        align-items: center;

        .toggle {
            display: block;
            position: absolute;
            // top: 45px;
            right: 50px;
        }

        .right {
            display: none;
        }

        .toggleIcon {
            // font-size: 48px;
            height: 32px;
            width: 32px;
        }

        .toggleRight {
            display: flex;
            width: 100vw;
            // height: calc(100vh - 100px);
            height: 100vh;
            position: absolute;
            left: 0;
            top: 0;

            -webkit-animation: slide-bottom .5s cubic-bezier(.25, .46, .45, .94) both;
            animation: slide-bottom .5s cubic-bezier(.25, .46, .45, .94) both;

            @-webkit-keyframes slide-bottom {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0)
                }

                100% {
                    -webkit-transform: translateY(100px);
                    transform: translateY(100px)
                }
            }

            @keyframes slide-bottom {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0)
                }

                100% {
                    -webkit-transform: translateY(100px);
                    transform: translateY(100px)
                }
            }

            .togglelinks {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                text-align: center;
                list-style: none;
                background-color: #002B5A;
                opacity: 0.9;
                width: 100%;
                // height: calc(100vh - 100px);
                height: 100%;

                .togglelink {

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: $on-primary-color;

                        &.current {
                            color: $secondary-color;
                            font-weight: 700;
                        }
                    }

                    span {
                        color: $on-primary-color;

                        .toggleicon {
                            margin-left: 5px;
                            width: 10px;
                            height: 10px;
                        }
                    }

                    ul {
                        display: none;
                    }

                    &:hover ul {
                        position: static;
                        font-size: 12px;
                        top: 0;
                        background-color: white;
                        // opacity: 1;
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        width: 100%;
                        row-gap: 8px;
                        transition: all 3s linear;
                        border: 1px solid rgba(0, 43, 90);

                        &.togglelong {
                            gap: 10px;
                            font-size: 12px;
                            width: 100%;
                            display: grid;
                            grid-template-columns: repeat(2, auto);
                            padding: 14px;
                        }

                        a {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:520px) {
    nav {
        padding: 0px 8px;
        height: 80px;

        .logo {
            width: 57px;
            height: 32px;
            margin-left: 14px;
        }

        .toggle {
            top: 30px;
            right: 30px;
        }

        .toggleRight {
            height: calc(100vh - 80px);
            top: -20px;
        }
    }
}





// Social.jsx
// .socials {
//     display: flex;
//     flex-direction: column;
//     position: fixed;
//     top: 40vh;
//     right: 16px;
//     z-index: -1;
//     height: 100vh;

//     .social {
//         margin-bottom: 24px;
//         // cursor: pointer;
//         // pointer-events: none;
//     }
// }
.socials {
    flex-direction: row;
    display: flex;
    justify-content: center;
    height: 20px;
    align-items: center;
    margin-bottom: 40px;


    .social {
        margin-right: 24px;
        // cursor: pointer;
    }
}

.socials2 {
    position: absolute;
    display: flex;
    flex-direction: column;
    // bottom: -16px;
    // top: 50%;
    // left: auto;
    right: 40px;
    z-index: 1;
    transform: translateY(50%);

    .social {
        margin-top: 24px;
        // cursor: pointer;
    }
}

@media only screen and (max-width:760px) {
    .socials {
        flex-direction: row;
        position: relative;
        bottom: 24px;
        top: auto;
        left: auto;
        right: auto;
        z-index: 1;
        justify-content: center;
        height: 20px;
        align-items: center;
        margin-bottom: 16px;
        margin: auto;

        .social {
            margin-right: 24px;
            width: 16px;
            height: 16px;
            // cursor: pointer;
        }
    }
}

@media only screen and (max-width:760px) {
    .socials2 {
        flex-direction: row;
        position: relative;
        bottom: 0px;
        top: auto;
        left: auto;
        right: auto;
        z-index: 1;
        justify-content: center;
        height: 20px;
        align-items: center;
        margin-bottom: 24px;
        margin: auto;

        .social {
            margin-right: 24px;
            width: 16px;
            height: 16px;
            // cursor: pointer;
        }
    }
}




// FirstCard.jsx
.firstCard {
    @include cardParam(row);
    border-radius: 30px 30px 30px 0px;
    border: 1px solid transaparent;
    margin: 60px 80px;
    // position: relative;
    margin-top: 140px;

    .firstCardContent {
        display: flex;
        flex-direction: column;
        padding: 70px 70px 70px 70px;

        h1 {
            margin-bottom: 16px;
            @include textParam($primary-color, $lato, 64px, 75px, 800)
        }

        p {
            margin-bottom: 30px;
            @include textParam($primary-color, $lato, 18px, 26px, 500)
        }

        div {
            display: flex;
            flex-direction: row;

            button {
                @include buttonParam($primary-color, $secondary-color);
                margin-right: 24px;

                &:hover {
                    background-color: $button-hover-color;
                }

                .social {
                    margin-right: 14px
                }

                &.secondaryBtn {
                    @include buttonParam($primary-color, white);
                    border: 2px solid $primary-color;

                    &:hover {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }

        }

    }

    img {
        border-radius: 0 30px 0 0;
        width: 45%;
    }
}

// FirstCard media queries
@media only screen and (max-width:1274px) {
    .firstCard {


        .firstCardContent {
            padding: 70px 60px;

            div {

                button {
                    padding: 12px 24px;

                    .social {
                        margin-right: 12px
                    }

                    &.secondaryBtn {
                        padding: 12px 24px;
                        font-size: 20px;

                    }
                }
            }
        }

        img {
            width: 45%;
        }
    }
}

@media only screen and (max-width:1130px) {
    .firstCard {

        .firstCardContent {
            padding: 70px 30px;

            div {

                button {
                    padding: 12px 24px;
                    font-size: 20px;

                    .social {
                        margin-right: 10px
                    }

                    &.secondaryBtn {
                        padding: 12px 24px;
                        font-size: 20px;

                    }
                }
            }
        }

        img {
            width: 40%;
        }
    }
}

@media only screen and (max-width:980px) {
    .firstCard {
        margin: 60px 40px;
    }
}

@media only screen and (max-width:870px) {
    .firstCard {

        .firstCardContent {

            h1 {
                @include textParam($primary-color, $lato, 55px, 70px, 800)
            }

            p {
                @include textParam($primary-color, $lato, 14px, 24px, 500)
            }

            div {

                button {
                    padding: 12px 24px;
                    font-size: 16px;

                    .social {
                        margin-right: 4px
                    }

                    &.secondaryBtn {
                        padding: 12px 24px;
                        font-size: 16px;

                    }
                }

            }

        }

    }
}

@media only screen and (max-width:760px) {
    .firstCard {
        @include cardParam(column);
        margin: 60px 40px;
        margin-top: 160px;

        .firstCardContent {
            padding: 40px 50px;

            h1 {
                margin-bottom: 16px;
                @include textParam($primary-color, $lato, 64px, 75px, 800)
            }

            p {
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 40px, 500)
            }

            div {

                button {
                    padding: 14px 28px;
                    font-size: 20px;

                    .social {
                        margin-right: 14px
                    }

                    &.secondaryBtn {
                        padding: 14px 28px;
                        font-size: 20px;

                    }
                }
            }
        }

        img {
            width: 100%;
        }
    }
}

@media only screen and (max-width:602px) {
    .firstCard {

        .firstCardContent {
            padding: 40px 30px;

            h1 {
                margin-bottom: 16px;
                @include textParam($primary-color, $lato, 55px, 75px, 800)
            }

            p {
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 16px, 40px, 500)
            }

            div {

                button {
                    padding: 12px 24px;
                    font-size: 16px;

                    .social {
                        margin-right: 8px
                    }

                    &.secondaryBtn {
                        padding: 12px 24px;
                        font-size: 16px;

                    }
                }
            }
        }

        img {
            width: 100%;
        }
    }
}

@media only screen and (max-width:520px) {
    .firstCard {
        margin: 120px 30px 60px;

        .firstCardContent {
            padding: 40px 20px;

            h1 {
                margin-bottom: 14px;
                @include textParam($primary-color, $lato, 35px, 60px, 800)
            }

            p {
                margin-bottom: 24px;
                @include textParam($primary-color, $lato, 14px, 35px, 500)
            }

            div {

                button {
                    padding: 8px 16px;
                    font-size: 10px;
                    margin-right: 16px;

                    .social {
                        margin-right: 5px;
                        width: 16px;
                        height: 16px;
                    }

                    &.secondaryBtn {
                        padding: 8px 16px;
                        font-size: 10px;
                    }
                }
            }
        }

        img {
            width: 100%;
            height: 350px;
        }
    }
}



// Statistics.jsx
.statistics {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 24px;
    z-index: 10;
    background-image: linear-gradient(rgba(255, 145, 0, 0.6), rgba(255, 145, 0, 0.6)),
        url('../assets/images/statisticsBackground.png');

    .statistic {
        margin: 48px 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .statisticIcon {
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }

        h3 {
            @include textParam(white, lato, 20px, 28px, 900);
            text-align: left
        }

        p {
            @include textParam(white, lato, 16px, 21px, 400);
            margin-top: 4px;
        }
    }
}

// Statistics media query
@media screen and (max-width:1190px) {
    .statistics {
        display: flex;

        .statistic {
            margin: 45px 30px;

            .statisticIcon {
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            h3 {
                @include textParam(white, lato, 18px, 25px, 900);
            }

            p {
                @include textParam(white, lato, 14px, 20px, 400);
                margin-top: 4px;
            }
        }
    }
}

@media screen and (max-width:840px) {
    .statistics {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .statistic {
            margin: 48px 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .statisticIcon {
                width: 40px;
                height: 40px;
                margin-right: 12px;
            }

        }
    }
}

@media screen and (max-width:480px) {
    .statistics {

        .statistic {
            margin: 48px 20px;

            .statisticIcon {
                width: 35px;
                height: 35px;
                margin-right: 12px;
            }

            h3 {
                @include textParam(white, lato, 10px, 20px, 900);
            }

            p {
                @include textParam(white, lato, 8px, 15px, 400);
                margin-top: 4px;
            }
        }
    }
}




// Facilities.jsx
.facilities {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0;

    header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        margin-top: 100px;

        h2 {
            margin: 0 20px;
            @include textParam($primary-color, lato, 50px, 67px, bold);
        }
    }

    section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 1px 200px;

        .textSpan {
            @include textParam($primary-color, lato, 18px, 27px, 400);
            padding: 10px 40px;
        }
    }
}

@media screen and (max-width:1024px) {
    .facilities {

        section {
            margin: 1px 200px;

            .textSpan {
                padding: 10px 10px;
            }
        }
    }
}

@media screen and (max-width:911px) {
    .facilities {

        section {
            margin: 1px 100px;

            .textSpan {
                padding: 10px 0;
            }
        }
    }
}

@media screen and (max-width:640px) {
    .facilities {
        margin: 80px 0;

        header {
            margin-bottom: 20px;

            h2 {
                margin: 0 20px;
                @include textParam($primary-color, lato, 30px, 40px, 400);
            }
        }

        section {
            margin: 1px 50px;

            .textSpan {
                @include textParam($primary-color, lato, 16px, 24px, 400);
                padding: 10px 0;
            }
        }
    }
}

@media screen and (max-width:520px) {
    .facilities {
        margin: 40px 0;

        header {
            margin: 0 0 14px;
            margin-top: 40px;

            h2 {
                margin: 0 10px;
                @include textParam($primary-color, lato, 25px, 35px, 400);
            }
        }

        section {
            margin: 0px 30px;

            .textSpan {
                @include textParam($primary-color, lato, 14px, 24px, 400);
                padding: 8px 0;
                // @include textParam($primary-color, $lato, 18px, 28px, regular)
            }
        }
    }
}

@media screen and (max-width:290px) {
    .facilities {

        header {
            margin-bottom: 12px;

            h2 {
                margin: 0 10px;
                @include textParam($primary-color, lato, 20px, 30px, 400);
            }
        }

        section {
            margin: 1px 10px;

            .textSpan {
                @include textParam($primary-color, lato, 12px, 18px, 400);
                padding: 8px 0;
            }
        }
    }
}



// SecondCard.jsx
.secondCard {
    @include cardParam(row-reverse);
    border-radius: 30px 30px 30px 30px;
    border: 1px solid $secondary-color;
    margin: 60px 80px;

    .secondCardContent {
        display: flex;
        flex-direction: column;
        padding: 40px 50px;
        width: 50%;
        // justify-content: space-between;

        h2 {
            margin-bottom: 30px;
            @include textParam($primary-color, $lato, 36px, 48px, bold);
            // background-image: l;
        }

        p {
            margin-bottom: 30px;
            @include textParam($primary-color, $lato, 18px, 28px, regular)
        }

        div {
            display: flex;

            button {

                &.secondaryBtn {
                    @include buttonParam($primary-color, white);
                    border: 2px solid $primary-color;

                    &:hover {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }

        }

    }

    .img {
        width: 50%;
        border-radius: 30px 0 0 30px;
        border: 0;
        object-fit: cover;
    }
}

@media only screen and (max-width:980px) {
    .secondCard {
        margin: 60px 40px;
    }
}

@media only screen and (max-width:760px) {
    .secondCard {
        @include cardParam(column);
        margin: 60px 40px;
        border-radius: 30px 30px 30px 30px;
        border: 1px solid $secondary-color;

        .secondCardContent {
            display: flex;
            flex-direction: column;
            padding: 40px 50px;
            width: 100%;

            h2 {
                margin-bottom: 20px;
                @include textParam($primary-color, $lato, 36px, 48px, 400);
            }

            p {
                margin-bottom: 20px;
                @include textParam($primary-color, $lato, 18px, 28px, regular)
            }

            div {
                display: flex;

                button {

                    &.secondaryBtn {
                        @include buttonParam($primary-color, white);
                        padding: 14px 28px;
                        border-radius: 50px;
                        font-size: 20px;
                        border: 2px solid $primary-color;

                        &:hover {
                            background-color: $primary-color;
                            color: white;
                        }
                    }
                }

            }

        }

        .img {
            width: 100%;
            border-radius: 0 0 30px 30px;
            border: 0;
        }
    }
}

@media only screen and (max-width:520px) {
    .secondCard {
        margin: 40px 30px;

        .secondCardContent {
            padding: 40px 30px;

            h2 {
                @include textParam($primary-color, $lato, 28px, 40px, 400);
            }

            p {
                @include textParam($primary-color, $lato, 14px, 24px, regular)
            }

            div {
                display: flex;

                button {

                    &.secondaryBtn {
                        @include buttonParam($primary-color, white);
                        border-radius: 50px;
                        padding: 8px 16px;
                        font-size: 10px;
                        border: 2px solid $primary-color;

                        &:hover {
                            background-color: $primary-color;
                            color: white;
                        }
                    }
                }

            }
        }

        img {
            object-fit: cover;
            width: 100%;
        }

    }
}



// Review
.review {
    @include cardParam(row);
    background-color: #002B5A;
    border-radius: 30px 30px 30px 30px;
    padding: 80px 50px;
    justify-content: space-between;
    align-items: center;
    margin: 100px 80px;

    .reviewContent {
        width: 75%;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;

        h2 {
            @include textParam($on-primary-color, lato, 50px, 60px, 700);
            margin-bottom: 30px;
        }

        p {
            @include textParam($on-primary-color, lato, 32px, 43px, 500);
            margin-bottom: 30px;
            text-align: left;
        }

        .customers {
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            h3 {
                @include textParam($on-primary-color, lato, 32px, 43px, bold);
                margin-right: 40px;
            }
        }


    }

    .customerImage {
        display: grid;
        // background-image: url('../assets/images/avatar.png');
        background: url('../assets/images/avatar.png') no-repeat center center/cover;
        width: 250px;
        height: 250px;
        // width: 100%;
        place-items: center;
        border-radius: 100%;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translateY(-50%);
        z-index: 99;

        img {
            // position: relative;
            border-radius: 100%;
        }

    }
}

@media only screen and (max-width:1200px) {
    .review {
        padding: 50px 40px;
        margin: 100px 80px;

        .reviewContent {
            margin-right: 5px;

            h2 {
                @include textParam($on-primary-color, lato, 45px, 55px, 700);
            }

            p {
                @include textParam($on-primary-color, lato, 28px, 35px, medium);
            }
        }
    }
}

@media only screen and (max-width:1050px) {
    .review {
        padding: 50px 30px;
        margin: 100px 60px;

        .reviewContent {
            margin-right: 4px;

            h2 {
                @include textParam($on-primary-color, lato, 37px, 55px, 700);
            }

            p {
                @include textParam($on-primary-color, lato, 22px, 35px, medium);
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .review {
        @include cardParam(column);
        background-color: #002B5A;
        padding: 50px;
        margin: 10px 30px;

        .reviewContent {
            width: 80vw;

            h2 {
                @include textParam($on-primary-color, lato, 40px, 48px, 700);
                margin-bottom: 30px;
            }

            p {
                @include textParam($on-primary-color, lato, 22px, 32px, medium);
                margin-bottom: 30px;
            }

            .customers {
                margin-bottom: 35px;

                h3 {
                    @include textParam($on-primary-color, lato, 28px, 43px, bold);
                    margin-right: 35px;
                }

                .grey {
                    width: 12px;
                    height: 12px;
                    background-color: grey;
                    border-radius: 50%;
                    margin-left: 4px;

                }

                .orange {
                    width: 12px;
                    height: 12px;
                    background-color: grey;
                    border-radius: 50%;
                    background-color: $secondary-color;
                }
            }
        }

        .customerImage {
            display: grid;
            // background-image: url('../assets/images/avatar.png');
            background: url('../assets/images/avatar.png') no-repeat center center/cover;
            width: 250px;
            height: 250px;
            // width: 100%;
            place-items: center;
            border-radius: 50%;
            margin-top: 30px;
        }
    }
}

@media only screen and (max-width:600px) {
    .review {
        padding: 20px 30px;
        margin: 60px 30px;

        .reviewContent {
            padding: 16px 8px;
            width: 75vw;

            h2 {
                @include textParam($on-primary-color, lato, 28px, 48px, 700);
                margin-bottom: 20px;
                text-align: left
            }

            p {
                @include textParam($on-primary-color, lato, 16px, 28px, medium);
                margin-bottom: 20px;
                text-align: left;
            }

            .customers {
                margin-bottom: 30px;

                h3 {
                    @include textParam($on-primary-color, lato, 18px, 43px, bold);
                    margin-right: 35px;
                }

                .grey {
                    width: 10px;
                    height: 10px;
                    background-color: grey;
                    border-radius: 50%;
                    margin-left: 4px;

                }
            }
        }

        .customerImage {
            display: grid;
            background: url('../assets/images/avatar.png') no-repeat center center/cover;
            width: 233px;
            height: 233px;
            // width: 100%;
            place-items: center;
            border-radius: 50%;
        }
    }
}



// RequestQuote.jsx
.requestQuote {
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../assets/images/quoteRequestBackground.jpg');
    background-size: cover;
    padding: 100px;
    align-items: center;
    margin-bottom: 60px;

    h2 {
        @include textParam($primary-color, lato, 36px, 48px, medium);
        margin-bottom: 30px;
    }

    p {
        @include textParam($primary-color, lato, 24px, 28px, regular);
        margin-bottom: 50px;
        text-align: center;
        padding: 0 20px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(4, auto);
        margin: 0 80px;
        gap: 20px;

        .card1 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/wedding.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card2 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/bridal.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card3 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/corporate.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card4 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/social.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }
}

@media only screen and (max-width:1200px) {
    .requestQuote {
        padding: 80px;

        p {
            @include textParam($primary-color, lato, 22px, 28px, regular);
            padding: 0 15px;
        }

        .cards {
            margin: 0 80px;
            gap: 15px;

            .card1 {
                height: 310px;
                padding: 40px 24px 24px 24px;
                width: 210px;

                div {
                    h3 {
                        @include textParam(white, lato, 20px, 27px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 14px, 20px, regular);
                    }
                }

                button {
                    padding: 12px 0;
                    font-size: 16px;
                }
            }

            .card2 {
                height: 310px;
                padding: 40px 24px 24px 24px;
                width: 210px;

                div {
                    h3 {
                        @include textParam(white, lato, 20px, 27px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 14px, 20px, regular);
                    }
                }

                button {
                    padding: 12px 0;
                    font-size: 16px;
                }
            }

            .card3 {
                height: 310px;
                padding: 40px 24px 24px 24px;
                width: 210px;

                div {
                    h3 {
                        @include textParam(white, lato, 20px, 27px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 14px, 20px, regular);
                    }
                }

                button {
                    padding: 12px 0;
                    font-size: 16px;
                }
            }

            .card4 {
                height: 310px;
                padding: 40px 24px 24px 24px;
                width: 210px;

                div {
                    h3 {
                        @include textParam(white, lato, 20px, 27px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 14px, 20px, regular);
                    }
                }

                button {
                    padding: 12px 0;
                    font-size: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width:1080px) {
    .requestQuote {

        p {
            @include textParam($primary-color, lato, 20px, 28px, regular);
            padding: 0 15px;
        }

        .cards {
            grid-template-columns: repeat(4, auto);
            margin: 0 80px;
            gap: 10px;

            .card1 {
                height: 310px;
                padding: 30px 24px 24px 24px;
                width: 190px;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 24px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 18px, regular);
                    }
                }

                button {
                    padding: 10px 0;
                    font-size: 14px;
                }
            }

            .card2 {
                height: 310px;
                padding: 30px 24px 24px 24px;
                width: 190px;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 24px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 18px, regular);
                    }
                }

                button {
                    padding: 10px 0;
                    font-size: 14px;
                }
            }

            .card3 {
                height: 310px;
                padding: 30px 24px 24px 24px;
                width: 190px;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 24px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 18px, regular);
                    }
                }

                button {
                    padding: 10px 0;
                    font-size: 14px;
                }
            }

            .card4 {
                height: 310px;
                padding: 30px 24px 24px 24px;
                width: 190px;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 24px, bold);
                        margin-bottom: 22px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 18px, regular);
                    }
                }

                button {
                    padding: 10px 0;
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (max-width:830px) {
    .requestQuote {
        padding: 50px;

        h2 {
            @include textParam($primary-color, lato, 24px, 36px, medium);
            margin-bottom: 30px;
        }

        p {
            @include textParam($primary-color, lato, 16px, 28px, regular);
            padding: 0 10px;
        }

        .cards {
            grid-template-columns: repeat(2, auto);
            gap: 10px;
        }
    }
}

@media only screen and (max-width:520px) {
    .requestQuote {
        padding: 30px;

        h2 {
            @include textParam($primary-color, lato, 18px, 36px, medium);
            margin-bottom: 24px;
        }

        p {
            @include textParam($primary-color, lato, 12px, 28px, regular);
        }

        .cards {
            gap: 24px;

            .card1 {
                height: 200px;
                padding: 0;
                width: 150px;
                justify-content: center;
                align-items: center;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 22px, bold);
                        margin-bottom: 22px;
                        padding: 20px 24px 0 24px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 15px, regular);
                        padding: 0 10px;
                    }
                }

                button {
                    padding: 8px 16px;
                    font-size: 12px;
                    margin-bottom: 20px;
                    width: 100%;
                }
            }

            .card2 {
                height: 200px;
                padding: 0;
                width: 150px;
                justify-content: center;
                align-items: center;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 22px, bold);
                        margin-bottom: 22px;
                        padding: 20px 24px 0 24px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 15px, regular);
                        padding: 0 10px;
                    }
                }

                button {
                    padding: 8px 16px;
                    font-size: 12px;
                    margin-bottom: 20px;
                    width: 100%;
                }
            }

            .card3 {
                height: 200px;
                padding: 0;
                width: 150px;
                justify-content: center;
                align-items: center;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 22px, bold);
                        margin-bottom: 22px;
                        padding: 20px 24px 0 24px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 15px, regular);
                        padding: 0 10px;
                    }
                }

                button {
                    padding: 8px 16px;
                    font-size: 12px;
                    margin-bottom: 20px;
                    width: 100%;
                }
            }

            .card4 {
                height: 200px;
                padding: 0;
                width: 150px;
                justify-content: center;
                align-items: center;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 22px, bold);
                        margin-bottom: 22px;
                        padding: 20px 24px 0 24px;
                    }

                    p {
                        @include textParam(white, lato, 12px, 15px, regular);
                        padding: 0 10px;
                    }
                }

                button {
                    padding: 8px 16px;
                    font-size: 12px;
                    margin-bottom: 0px;
                    width: 100%;
                }
            }
        }
    }
}



// NewsLetter.jsx
.newsLetter,
.newsLetter2 {
    display: flex;
    flex-direction: column;
    background: linear-gradient(rgba(0, 25, 52, 0.8), rgba(0, 25, 25, 0.8)), url('../assets/images/newsletters.png');
    background-size: cover;
    padding: 50px;
    align-items: center;
    margin-bottom: 60px;
    text-align: center;
    padding: 0 350px;
    margin-top: 100px;

    img {
        width: 80px;
        height: 86px;
        margin: 60px 0 24px 0;
    }

    h1 {
        @include textParam($on-primary-color, lato, 36px, 36px, bold);
        margin-bottom: 24px;
    }

    span {
        @include textParam($on-primary-color, lato, 24px, 36px, regular);
        margin-bottom: 24px;
    }

    .subscribe {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 90%;
        padding-bottom: 60px;

        .input {
            width: 100%;
            background-color: $on-primary-color;
            color: #001934;
            border: 0;
            padding: 20px;
            outline: 0;
            font-size: 22px;

            &::placeholder {
                color: #001934;
                font-size: 22px;
            }
        }

        .submit {
            width: 30%;
            padding: 20px 0;
            background-color: $secondary-color;
            color: #001934;
            border: 0;
            font-size: 22px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }
}

@media only screen and (max-width:1280px) {
    .newsLetter {
        padding: 50px;
        padding: 0 250px;
    }
}

@media only screen and (max-width:1070px) {
    .newsLetter {
        padding: 50px;
        padding: 0 150px;
    }
}

@media only screen and (max-width:870px) {
    .newsLetter {
        padding: 50px;
        padding: 0 60px;

        h1 {
            @include textParam($on-primary-color, lato, 32px, 32px, bold);
            margin-bottom: 24px;
        }

        span {
            @include textParam($on-primary-color, lato, 20px, 36px, regular);
            margin-bottom: 24px;
        }
    }
}

@media only screen and (max-width:680px) {
    .newsLetter {
        padding: 50px;
        padding: 0 20px;
        margin-top: 80px;

        h1 {
            @include textParam($on-primary-color, lato, 28px, 28px, bold);
            margin-bottom: 24px;
        }

        span {
            @include textParam($on-primary-color, lato, 18px, 36px, regular);
            margin-bottom: 24px;
        }

        .subscribe {
            width: 100%;

            .input {
                font-size: 18px;

                &::placeholder {
                    font-size: 18px;
                }
            }

            .submit {
                width: 35%;
                font-size: 18px;

                &:hover {
                    background-color: $button-hover-color;
                }
            }
        }
    }
}

@media only screen and (max-width:484px) {
    .newsLetter {
        padding: 50px;
        padding: 0 10px;

        img {
            width: 60px;
            height: 66px;
        }

        h1 {
            @include textParam($on-primary-color, lato, 24px, 24px, bold);
            margin-bottom: 22px;
        }

        span {
            @include textParam($on-primary-color, lato, 16px, 32px, regular);
            margin-bottom: 22px;
        }

        .subscribe {
            width: 100%;

            .input {
                font-size: 16px;

                &::placeholder {
                    font-size: 16px;
                }
            }

            .submit {
                width: 35%;
                font-size: 16px;

                &:hover {
                    background-color: $button-hover-color;
                }
            }
        }
    }
}




// Contact.jsx
.contact {
    display: flex;
    padding: 120px 80px;
    background: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/contactBackground.jpg');
    background-size: cover;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;

    .content {
        display: flex;
        flex-direction: column;
        width: 35%;

        h3 {
            @include textParam(white, Segoe UI, 50px, 67px, bold);
            margin-bottom: 30px;
        }

        p {
            @include textParam(white, Segoe UI, 24px, 30px, medium);
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 30px;
        background-color: white;
        border-radius: 16px;
        width: 541px;

        h3 {
            @include textParam($primary-color, Segoe UI, 33px, 44px, bold);
            margin-bottom: 24px;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 30px;

            input {
                display: block;
                width: 100%;
                height: 50px;
                margin-bottom: 16px;
                background-color: $primary-color;
                color: white;
                border: 0;
                border-radius: 8px;
                padding-left: 16px;
                outline: 0;
                font-size: 16px;

                &::placeholder {
                    color: white;
                    font-size: 16px;
                }

            }

            textarea {
                height: 108px;
                display: block;
                width: 100%;
                // height: 50px;
                margin-bottom: 16px;
                background-color: $primary-color;
                color: white;
                border: 0;
                border-radius: 8px;
                padding-left: 16px;
                padding-top: 16px;
                outline: 0;
                font-size: 16px;
                overflow: hidden;

                &::placeholder {
                    @include textParam(white, lato, 16px, 19px, bold);
                }
            }

            button {
                width: 100%;
                padding: 16px 0;
                background-color: $secondary-color;
                color: $primary-color;
                border: 0;
                border-radius: 50px;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: $button-hover-color;
                }
            }
        }

        .contacts {
            display: flex;
            justify-content: space-between;
            width: 100%;

            span {
                @include textParam($primary-color, lato, 14px, 17px, semibold);
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 8px;
                }
            }
        }
    }

}

@media only screen and (max-width:1200px) {
    .contact {

        .content {
            width: 40%;
        }

        .formContainer {
            width: 450px;
        }
    }
}

@media only screen and (max-width:990px) {
    .contact {
        padding: 80px 40px;

        .content {
            width: 35%;

            h3 {
                @include textParam(white, Segoe UI, 45px, 65px, bold);
                margin-top: -100px;
            }

            p {
                @include textParam(white, Segoe UI, 18px, 28px, medium);
            }
        }

        .formContainer {
            width: 400px;
        }
    }
}

@media only screen and (max-width:740px) {
    .contact {
        display: flex;
        flex-direction: column;
        background: none;
        padding: 0 0;
        align-items: center;
        justify-content: center;

        .content {
            display: flex;
            background: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/contactBackground.jpg'), no-repeat;
            background-size: cover;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            width: 100%;
            padding: 230px 100px 100px 100px;

            h3 {
                @include textParam(white, Segoe UI, 45px, 50px, bold);
                margin-bottom: 30px;
            }

            p {
                @include textParam(white, Segoe UI, 20px, 40px, medium);
                // width: 100%;
            }
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 50px;
            background-color: white;
            border-radius: 16px;
            width: 100%;

            h3 {
                @include textParam($primary-color, Segoe UI, 33px, 44px, bold);
                margin-bottom: 24px;
            }

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;

                input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    outline: 0;
                    font-size: 16px;

                    &::placeholder {
                        color: white;
                        font-size: 16px;
                    }

                }

                textarea {
                    height: 108px;
                    display: block;
                    width: 100%;
                    // height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    padding-top: 16px;
                    outline: 0;
                    font-size: 16px;
                    overflow: hidden;

                    &::placeholder {
                        @include textParam(white, lato, 16px, 19px, bold);
                    }
                }

                button {
                    width: 100%;
                    padding: 16px 0;
                    background-color: $secondary-color;
                    color: $primary-color;
                    border: 0;
                    border-radius: 50px;
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;

                }
            }

            .contacts {
                display: flex;
                justify-content: space-between;
                width: 100%;

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width:600px) {
    .contact {
        margin-top: 80px;

        .content {
            padding: 230px 16px 100px 16px;

            h3 {
                @include textParam(white, Segoe UI, 40px, 30px, bold);
                margin-bottom: 30px;
            }

            p {
                @include textParam(white, Segoe UI, 18px, 35px, medium);
                width: 100%;
            }
        }

        .formContainer {
            padding: 40px;
            width: 100%;

            h3 {
                @include textParam($primary-color, Segoe UI, 30px, 32px, bold);
            }

            form {
                margin-bottom: 30px;

                input {
                    height: 50px;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }

                }

                textarea {
                    height: 108px;
                    font-size: 14px;

                    &::placeholder {
                        @include textParam(white, lato, 14px, 19px, bold);
                    }
                }

                button {
                    font-size: 16px;

                }
            }

            .contacts {

                span {
                    @include textParam($primary-color, lato, 9px, 17px, semibold);

                    .icon {
                        margin-right: 4px;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width:440px) {
    .contact {

        .content {
            width: 100%;
            padding: 230px 16px 100px 16px;

            h3 {
                @include textParam(white, Segoe UI, 36px, 30px, bold);
                margin-bottom: 30px;
            }

            p {
                @include textParam(white, Segoe UI, 16px, 35px, medium);
                width: 100%;
            }
        }

        .formContainer {
            padding: 40px 20px;
            width: 100%;

            h3 {
                @include textParam($primary-color, Segoe UI, 24px, 32px, bold);
            }

            form {
                margin-bottom: 30px;

                input {
                    height: 50px;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }

                }

                textarea {
                    height: 108px;
                    font-size: 14px;

                    &::placeholder {
                        @include textParam(white, lato, 14px, 19px, bold);
                    }
                }

                button {
                    font-size: 16px;

                }
            }

            .contacts {

                span {
                    @include textParam($primary-color, lato, 9px, 17px, semibold);

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }

    }
}



// Footer.jsx
footer {
    display: flex;
    flex-direction: column;
    margin: 80px 0 0 0;
    width: 100%;
    background-color: #F5F5F5;

    .callBack {
        padding: 80px;
        width: 100%;
        background-color: #001934;
        margin: 70px 0;

        h3 {
            @include textParam(white, lato, 54px, 65px, bold);
        }

        p {
            @include textParam(white, lato, 32px, 39px, bold);
            margin-bottom: 40px;
        }

        div {
            display: flex;
            align-items: center;

            form {
                display: flex;

                input {
                    width: 265px;
                    padding: 14px 14px;
                    background-color: white;
                    color: $primary-color;
                    border: 0;
                    border-radius: 4px;
                    margin-right: 24px;
                    font-size: 24px;
                    font-weight: medium;
                    outline: 0;

                    &::placeholder {
                        color: $primary-color;
                        font-size: 24px;
                        font-weight: medium;
                    }
                }

                button {
                    width: 185px;
                    padding: 14px 14px;
                    background-color: $secondary-color;
                    color: $primary-color;
                    border: 0;
                    border-radius: 4px;
                    margin-right: 24px;
                    font-size: 24px;
                    text-transform: uppercase;
                    font-weight: 800;
                    cursor: pointer;

                    &:hover {
                        background-color: $button-hover-color;
                    }

                }
            }

            span {
                display: flex;
                align-items: center;
                @include textParam(white, lato, 18px, 22px, regular);

                .icon {
                    margin-right: 8px;
                }

            }
        }
    }

    .address {
        display: flex;
        flex-direction: column;
        background-color: $primary-color;
        padding: 40px 80px;

        .logo {
            width: 210px;
            height: 120px;
        }

        .aboutWosam {
            display: flex;
            align-items: flex-start;
            margin-top: 24px;
            column-gap: 20px;

            .wosamAddress {
                display: flex;
                flex-direction: column;

                .mainAddress {
                    color: white;
                    margin-bottom: 15px;

                    h4 {
                        @include textParam(white, lato, 22px, 27px, bold);
                        margin-bottom: 5px;

                    }

                    p {
                        @include textParam(white, lato, 16px, 19px, regular);

                    }
                }
            }

            .siteNavigations {
                color: white;
                width: 70%;

                h4 {
                    @include textParam(white, lato, 22px, 27px, bold);
                    margin-bottom: 20px;
                }

                .navigations {
                    display: grid;
                    grid-template-columns: repeat(4, auto);
                    row-gap: 20px;
                    column-gap: 30px;

                    a {
                        @include textParam(white, lato, 16px, 19px, regular);
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 16px;
                    }
                }
            }

            .location {
                color: white;
                // width: 100%;

                h4 {
                    @include textParam(white, lato, 22px, 27px, bold);
                    margin-bottom: 20px;
                }


            }
        }
    }

    .copyright {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #001934;
        padding: 30px 80px;

        span {
            color: #fff;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 18px;

            strong {
                text-decoration: underline;
            }
        }

        .sociallinks {
            color: #fff;
            display: flex;
            margin-right: 50px;

            .socialLink {
                margin-right: 5px;
                width: 28px;
                height: 21px;
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width:1279px) {
    footer {

        .callBack {
            padding: 60px 40px;
            margin: 50px 0;

            h3 {
                @include textParam(white, lato, 48px, 60px, bold);
            }

            p {
                @include textParam(white, lato, 28px, 35px, bold);
                margin-bottom: 35px;
            }

            div {

                form {

                    input {
                        width: 255px;
                        padding: 14px 14px;
                        margin-right: 20px;
                        font-size: 20px;

                        &::placeholder {
                            color: $primary-color;
                            font-size: 20px;
                        }
                    }

                    button {
                        width: 175px;
                        padding: 14px 14px;
                        margin-right: 20px;
                        font-size: 20px;

                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    @include textParam(white, lato, 16px, 20px, regular);

                }
            }
        }

        .address {
            padding: 30px 40px;

            .logo {
                width: 170px;
                height: 90px;
            }

            .aboutWosam {
                margin-top: 20px;
                column-gap: 10px;

                .wosamAddress {

                    .mainAddress {
                        margin-bottom: 20px;

                        h4 {
                            @include textParam(white, lato, 20px, 25px, bold);
                            margin-bottom: 5px;

                        }

                        p {
                            @include textParam(white, lato, 14px, 17px, regular);

                        }
                    }
                }

                .siteNavigations {

                    h4 {
                        @include textParam(white, lato, 20px, 25px, bold);
                        margin-bottom: 20px;
                    }

                    .navigations {
                        row-gap: 15px;
                        column-gap: 25px;

                        a {
                            @include textParam(white, lato, 14px, 17px, regular);

                        }
                    }
                }

                .location {

                    h4 {
                        @include textParam(white, lato, 20px, 25px, bold);
                        margin-bottom: 20px;
                    }


                }
            }
        }

        .copyright {
            padding: 30px 40px;

            span {
                font-size: 16px;

            }

            .sociallinks {
                margin-right: 50px;

                .socialLink {
                    width: 26px;
                    height: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width:991px) {
    footer {

        .callBack {
            padding: 60px 30px;

            h3 {
                @include textParam(white, lato, 45px, 55px, bold);
            }

            p {
                @include textParam(white, lato, 25px, 30px, bold);
            }

            div {

                form {

                    input {
                        width: 215px;
                        padding: 12px 12px;
                        margin-right: 15px;
                        font-size: 18px;

                        &::placeholder {
                            font-size: 18px;
                        }
                    }

                    button {
                        width: 145px;
                        padding: 12px 10px;
                        margin-right: 15px;
                        font-size: 18px;

                    }
                }
            }
        }

        .address {
            padding: 30px;

            .logo {
                width: 140px;
                height: 80px;
            }

            .aboutWosam {

                .siteNavigations {

                    .navigations {
                        gap: 10px;
                    }
                }

                .location {

                    img {
                        width: 200px;
                        height: 150px;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width:768px) {
    footer {

        .callBack {
            padding: 60px 20px;

            h3 {
                @include textParam(white, lato, 40px, 50px, bold);
            }

            p {
                @include textParam(white, lato, 22px, 27px, bold);
            }

            div {

                form {

                    input {
                        width: 150px;
                        padding: 10px;
                        font-size: 14px;
                        margin-right: 10px;

                        &::placeholder {
                            font-size: 14px;
                        }

                    }

                    button {
                        width: 100px;
                        padding: 10px;
                        margin-right: 10px;
                        font-size: 14px;
                    }
                }

                span {
                    @include textParam(white, lato, 14px, 16px, regular);
                    flex-wrap: wrap;

                    .icon {
                        margin-right: 4px;
                        width: 14px;
                    }

                }
            }
        }

    }
}

@media only screen and (max-width:520px) {
    footer {
        margin: 40px 0 0 0;

        .callBack {
            padding: 60px 20px;
            margin: 50px 0;

            h3 {
                @include textParam(white, lato, 28px, 37px, bold);
                margin-bottom: 8px
            }

            p {
                @include textParam(white, lato, 18px, 24px, bold);
                margin-bottom: 30px;
            }

            div {
                width: 70%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // justify-content: center;
                position: relative;

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    input {
                        width: 100%;
                        padding: 12px 12px;
                        margin-bottom: 16px;
                        font-size: 16px;

                        &::placeholder {
                            font-size: 16px;
                        }
                    }

                    button {
                        width: 40%;
                        padding: 12px 12px;
                        margin-bottom: 1px;
                        font-size: 15px;

                    }
                }

                span {
                    display: flex;
                    flex-wrap: nowrap;
                    @include textParam(white, lato, 9px, 15px, regular);
                    position: absolute;
                    bottom: 32px;
                    left: 140px;

                    .icon {
                        margin-right: 5px;
                        width: 10px;
                        height: 10px;
                    }

                }
            }
        }

        .address {
            padding: 30px 20px;
            align-items: center;

            .logo {
                width: 90px;
                height: 50px;
            }

            .aboutWosam {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                column-gap: 0;

                .wosamAddress {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .mainAddress {
                        margin-bottom: 5px;
                        padding: 5px 50px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h4 {
                            @include textParam(white, lato, 16px, 19px, bold);
                            margin-bottom: 10px;

                        }

                        p {
                            @include textParam(white, lato, 12px, 18px, regular);
                            text-align: center;
                        }
                    }
                }

                .siteNavigations {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    margin: 50px 0;

                    h4 {
                        @include textParam(white, lato, 16px, 19px, bold);
                        margin-bottom: 20px;
                    }

                    .navigations {
                        display: grid;
                        grid-template-columns: repeat(2, auto);
                        row-gap: 20px;
                        column-gap: 40px;

                        a {
                            @include textParam(white, lato, 12px, 18px, regular);

                        }
                    }
                }

                .location {
                    width: 100%;

                    h4 {
                        @include textParam(white, lato, 16px, 19px, bold);
                        margin-bottom: 16px;
                    }

                    img {
                        width: 100%;
                        height: 150px;
                    }


                }
            }
        }

        .copyright {
            padding: 20px;

            span {
                font-size: 11px;

            }

            .sociallinks {
                margin-right: 0;

                .socialLink {
                    margin-right: 7px;
                    width: 13px;
                    height: 10px;
                }
            }
        }
    }
}


// Falomo Page
.falomoPage {
    display: flex;
    flex-direction: column;

    header {
        background: linear-gradient(rgba(0, 43, 90, 0.5), rgba(0, 43, 90, 0.5)), url('../assets/images/falomo.png') no-repeat center center/cover;
        height: 450px;
        padding: 0px 80px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            @include textParam(white, lato, 64px, 80px, heavy);
            width: 45%;
        }
    }

    section {
        padding: 40px 80px;

        h2 {
            @include textParam($primary-color, lato, 32px, 40px, bold);
            margin-bottom: 16px;
        }
        
        p {
            @include textParam($primary-color, lato, 18px, 28px, medium);
        }
        div {
            display: flex;
            align-items: center;
            gap: 40px;
            padding: 24px 0px;
        }
    }
}

@media only screen and (max-width:600px) {
    .falomoPage {
        header {
            padding: 60px 16px;
            margin-top: 79px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 320px;

            h1 {
                @include textParam(white, lato, 40px, 54px, heavy);
                width: 80%;
            }
        }

        section {
            padding: 40px 16px;

            h2 {
                @include textParam($primary-color, lato, 24px, 32px, bold);
                margin-bottom: 8px;
            }

            p {
                @include textParam($primary-color, lato, 16px, 28px, normal);
            }
        }
    }

}


// AboutUs Page
.aboutUsPage {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    .aboutUs {
        background: linear-gradient(rgba(0, 43, 90, 0.5), rgba(0, 43, 90, 0.5)), url('../assets/images/About Wosam Arena About Us.png') no-repeat center center/cover;
        display: grid;
        place-items: center;
        // height: 430px;
        width: 100%;
        padding: 100px;
        margin-bottom: 100px;
        margin-top: 100px;

        h2 {
            @include textParam(white, lato, 70px, 95px, heavy);
            margin-bottom: 30px
        }

        p {
            @include textParam(white, lato, 24px, 40px, medium);
            width: 80%;
            text-align: center;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            border-radius: 50px;
            margin-top: 24px;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 2.5fr;
        padding: 0 80px;
        column-gap: 40px;

        .title {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            span {
                @include dashParam();
                margin-bottom: 16px;
                display: flex;

            }

            h3 {
                @include textParam($primary-color, lato, 51px, 68px, bold);
                margin-bottom: 8px;
            }

            p {
                @include textParam($primary-color, lato, 30px, 40px, regular);
            }

        }

        p {
            @include textParam($primary-color, lato, 18px, 40px, regular);

        }

    }

}

@media only screen and (max-width:1280px) {
    .aboutUsPage {

        .aboutUs {
            margin-bottom: 90px;

            h2 {
                @include textParam(white, lato, 70px, 95px, heavy);
                margin-bottom: 25px
            }

            p {
                @include textParam(white, lato, 24px, 40px, medium);
                width: 90%;
                text-align: center;
            }
        }

        .content {
            padding: 0 70px;
            column-gap: 20px;

            .title {

                span {
                    @include dashParam();
                    margin-bottom: 14px;

                }

                h3 {
                    @include textParam($primary-color, lato, 47px, 65px, bold);
                    margin-bottom: 6px;
                }

                p {
                    @include textParam($primary-color, lato, 27px, 35px, regular);
                }

            }

            p {
                @include textParam($primary-color, lato, 17px, 37px, regular);

            }

        }

    }
}

@media only screen and (max-width:1024px) {
    .aboutUsPage {

        .aboutUs {
            margin-bottom: 90px;

            h2 {
                @include textParam(white, lato, 67px, 90px, heavy);
                margin-bottom: 25px
            }

            p {
                @include textParam(white, lato, 22px, 37px, medium);
                width: 95%;
            }
        }

        .content {
            padding: 0 50px;
            column-gap: 10px;

            .title {

                span {
                    @include dashParam();
                    margin-bottom: 12px;

                }

                h3 {
                    @include textParam($primary-color, lato, 45px, 60px, bold);
                    margin-bottom: 6px;
                }

                p {
                    @include textParam($primary-color, lato, 25px, 32px, regular);
                }

            }

            p {
                @include textParam($primary-color, lato, 16px, 35px, regular);

            }

        }

    }
}

@media only screen and (max-width:991px) {
    .aboutUsPage {

        .aboutUs {

            p {
                width: 100%;
            }
        }

    }
}

@media only screen and (max-width:768px) {
    .aboutUsPage {

        .aboutUs {
            margin-bottom: 80px;
            // margin-top: 100px;

            h2 {
                @include textParam(white, lato, 65px, 85px, heavy);
                margin-bottom: 20px
            }

            p {
                @include textParam(white, lato, 20px, 35px, medium);
            }
        }

        .content {
            padding: 0 20px;

            .title {

                span {
                    margin-bottom: 5px;

                }

                h3 {
                    @include textParam($primary-color, lato, 40px, 50px, bold);
                    margin-bottom: 6px;
                }

                p {
                    @include textParam($primary-color, lato, 21px, 27px, regular);
                }

            }

            p {
                @include textParam($primary-color, lato, 15px, 32px, regular);

            }

        }

    }
}

@media only screen and (max-width:540px) {
    .aboutUsPage {
        margin-bottom: 30px;

        .aboutUs {
            width: 100%;
            padding: 70px 30px;
            margin-bottom: 40px;
            margin-top: 80px;

            h2 {
                @include textParam(white, lato, 32px, 39px, heavy);
                margin-bottom: 25px
            }

            p {
                @include textParam(white, lato, 12px, 20px, medium);
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            margin-bottom: 0px;

            .title {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-bottom: 10px;

                span {
                    margin-bottom: 14px;

                }

                h3 {
                    @include textParam($primary-color, lato, 24px, 32px, bold);
                    // margin-bottom: 8px;
                }

                p {
                    @include textParam($primary-color, lato, 18px, 28px, regular);
                }

            }

            p {
                @include textParam($primary-color, lato, 12px, 20px, regular);

            }

        }

    }
}




// GalleryPage.jsx
.galleryPage {
    header {
        display: grid;
        padding-top: 50px;
        place-items: center;
        margin-top: 100px;


        span {
            @include dashParam();
        }

        h2 {
            @include textParam($primary-color, lato, 56px, 64px, 800);
            margin-top: 16px
        }

        p {
            @include textParam($primary-color, lato, 24px, 32px, medium);
            width: 25%;
            text-align: center;
            margin-top: 16px;
        }
    }

    .buju {
        display: grid;
        place-items: center;
        padding: 155px 0;
        background-image: linear-gradient(rgba(255, 145, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../assets/images/buju.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        margin-top: 50px;

        h3 {
            @include textParam(white, lato, 56px, 64px, regular);
            text-align: center;
            width: 45%;
            margin-bottom: 24px;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);

            &:hover {
                background-color: $button-hover-color;
            }

            .social {
                margin-right: 16px
            }

            &.secondaryBtn {
                @include buttonParam($primary-color, white);
                border: 2px solid $primary-color;

                &:hover {
                    background-color: $primary-color;
                    color: white;
                }
            }
        }
    }

    .slideshow {
        display: flex;
        flex-direction: column;
        margin: 50px 0;
        width: 100%;

        h3 {
            @include textParam($primary-color, lato, 40px, 54px, medium);
            margin-bottom: 32px;
            text-align: center;
        }

        .slides {
            display: grid;
            grid-template-columns: repeat(3, auto);
            margin: 0 80px;
            height: 280px;
            // width: 100%;
            column-gap: 24px;

            .slide1 {
                background-image: url('../assets/images/slide1.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            .slide2 {
                background-image: url('../assets/images/slide2.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            .slide3 {
                background-image: url('../assets/images/slide3.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

        }

        .slidesSmallscreen {
            display: flex;
        }
    }

    .pictures {
        display: flex;
        flex-direction: column;
        margin: 50px 0;
        width: 100%;
        height: 100%;

        h3 {
            @include textParam($primary-color, lato, 40px, 54px, medium);
            margin-bottom: 32px;
            text-align: center;
        }

        .slides {
            display: grid;
            grid-template-columns: repeat(3, auto);
            margin: 0 80px;
            height: 650px;
            // width: 100%;
            column-gap: 24px;

            .slide1 {
                background-image: url('../assets/images/picture1.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            .slide2 {
                display: grid;
                grid-template-rows: repeat(1, auto);
                row-gap: 24px;

                .slide1 {
                    background-image: url('../assets/images/picture21.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }

                .slide2 {
                    background-image: url('../assets/images/picture3.jpg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }

            .slide3 {
                background-image: url('../assets/images/picture4.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

        }
    }

    .requestQuote2 {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/galleryRequestQuoteBg.jpg');
        width: 100%;
        display: grid;
        place-items: center;
        padding: 100px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 30px;
            background-color: white;
            border-radius: 16px;
            width: 541px;

            h3 {
                @include textParam($primary-color, lato, 33px, 44px, bold);
                margin-bottom: 24px;
            }

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;

                div {
                    display: flex;
                    column-gap: 16px;
                }

                input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    outline: 0;
                    font-size: 16px;

                    &::placeholder {
                        color: white;
                        font-size: 16px;
                    }

                }

                textarea {
                    height: 108px;
                    display: block;
                    width: 100%;
                    // height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    padding-top: 16px;
                    outline: 0;
                    font-size: 16px;
                    overflow: hidden;

                    &::placeholder {
                        @include textParam(white, lato, 16px, 19px, bold);
                    }
                }

                button {
                    width: 100%;
                    padding: 16px 0;
                    background-color: $secondary-color;
                    color: $primary-color;
                    border: 0;
                    border-radius: 50px;
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }
            }

            .contacts {
                display: flex;
                justify-content: space-between;
                width: 100%;

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }


    }
}

@media only screen and (max-width:1024px) {
    .galleryPage {

        header {
            display: grid;
            padding-top: 50px;
            place-items: center;

            h2 {
                @include textParam($primary-color, lato, 56px, 64px, 800);
                margin-top: 16px
            }

            p {
                @include textParam($primary-color, lato, 24px, 32px, medium);
                width: 25%;
                text-align: center;
                margin-top: 16px;
            }
        }

        .buju {
            display: grid;
            place-items: center;
            padding: 140px 0;
            background-size: cover;
            margin-top: 50px;

            h3 {
                @include textParam(white, lato, 56px, 64px, regular);
                text-align: center;
                width: 70%;
                margin-bottom: 24px;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);

                &:hover {
                    background-color: $button-hover-color;
                }

                .social {
                    margin-right: 15px
                }

                &.secondaryBtn {
                    @include buttonParam($primary-color, white);
                    border: 2px solid $primary-color;

                    &:hover {
                        background-color: $primary-color;
                        color: white;
                    }
                }
            }
        }

        .slideshow {
            margin: 50px 0;

            .slides {
                margin: 0 30px;
                column-gap: 20px;
            }
        }

        .pictures {

            .slides {
                margin: 0 30px;
                column-gap: 20px;

                .slide2 {
                    row-gap: 20px;
                }

            }
        }

        .requestQuote2 {
            padding: 100px;

            .formContainer {
                width: 520px;

                form {

                    div {
                        column-gap: 14px;
                    }

                    input {
                        margin-bottom: 14px;
                    }

                    textarea {
                        margin-bottom: 14px;
                    }
                }

            }


        }
    }
}

@media only screen and (max-width:991px) {
    .galleryPage {

        header {

            h2 {
                @include textParam($primary-color, lato, 50px, 60px, 800);
            }

            p {
                @include textParam($primary-color, lato, 21px, 28px, medium);
                width: 40%;
            }
        }

        .buju {
            padding: 140px 0;
            background-size: cover;

            h3 {
                @include textParam(white, lato, 50px, 60px, regular);
                width: 90%;
            }

            button {
                .social {
                    margin-right: 10px
                }
            }
        }

        .slideshow {
            margin: 50px 0;

            .slides {
                margin: 0 20px;
                column-gap: 16px;
            }
        }

        .pictures {

            .slides {
                margin: 0 20px;
                column-gap: 16px;

                .slide2 {
                    row-gap: 16px;
                }

            }
        }

        .requestQuote2 {
            padding: 100px;

            .formContainer {
                width: 500px;
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .galleryPage {
        header {
            margin-top: 80px;

            h2 {
                @include textParam($primary-color, lato, 32px, 39px, 800);
            }

            p {
                @include textParam($primary-color, lato, 16px, 25px, medium);
                width: 232px;
            }
        }

        .buju {
            padding: 120px 0;
            background-size: cover;
            margin-top: 40px;

            h3 {
                @include textParam(white, lato, 32px, 35px, regular);
                width: 80%;
                margin-bottom: 20px;
            }

            button {
                padding: 8px 16px;
                font-size: 10px;

                .social {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .slideshow {
            display: flex;
            flex-direction: column;
            margin: 50px 0;
            width: 100%;

            h3 {
                @include textParam($primary-color, lato, 24px, 29px, medium);
                margin-bottom: 20px;
            }

            .slides {
                display: none;
            }

            .slidesSmallscreen {
                display: flex;
                height: 400px;
            }
        }

        .pictures {

            h3 {
                @include textParam($primary-color, lato, 24px, 29px, medium);
                margin-bottom: 30px;
            }

            .slides {
                display: grid;
                height: 300px;
                column-gap: 10px;

                .slide1 {
                    background-size: contain;
                }

                .slide2 {
                    row-gap: 0;
                    height: 100%;

                    .slide1 {
                        margin-top: 10px;
                        background-size: contain;
                    }

                    .slide2 {
                        margin-top: -10px;
                        // margin-bottom: 10px;
                        background-size: contain;
                    }
                }

                .slide3 {
                    background-size: contain;
                }

            }
        }

        .requestQuote2 {
            padding: 80px 24px;

            .formContainer {
                width: 100%;
                padding: 30px 16px;

                h3 {
                    @include textParam($primary-color, lato, 22px, 30px, bold);
                    margin-bottom: 20px;
                }

                form {
                    margin-bottom: 20px;

                    div {
                        column-gap: 10px;
                    }

                    input {
                        height: 35px;
                        margin-bottom: 12px;
                        padding-left: 12px;
                        font-size: 12px;

                        &::placeholder {
                            font-size: 12px;
                        }

                    }

                    textarea {
                        height: 75px;
                        margin-bottom: 12px;
                        padding-left: 12px;
                        padding-top: 12px;
                        font-size: 12px;

                        &::placeholder {
                            @include textParam(white, lato, 12px, 15px, bold);
                        }
                    }

                    button {
                        padding: 12px 0;
                        font-size: 12px;
                    }
                }

                .contacts {

                    span {
                        @include textParam($primary-color, lato, 9px, 12px, semibold);

                        .icon {
                            margin-right: 4px;
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:490px) {
    .galleryPage {
        .pictures {
            .slides {
                .slide2 {
                    .slide1 {
                        margin-top: 17px;
                    }

                    .slide2 {
                        margin-top: -12px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:420px) {
    .galleryPage {
        .pictures {
            .slides {
                .slide2 {
                    .slide1 {
                        margin-top: 30px;
                    }

                    .slide2 {
                        margin-top: -26px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:380px) {
    .galleryPage {
        .pictures {
            .slides {
                .slide2 {
                    .slide1 {
                        margin-top: 36px;
                    }

                    .slide2 {
                        margin-top: -30px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:350px) {
    .galleryPage {
        .pictures {
            .slides {
                .slide2 {
                    .slide1 {
                        margin-top: 44px;
                    }

                    .slide2 {
                        margin-top: -40px;
                    }
                }
            }
        }
    }
}




// Our SPaces Page
// .ourSpacesPage {
//     display: flex;
//     flex-direction: column;
//     width: 100%;

//     .spaces {
//         display: flex;
//         width: 100%;

//         img {
//             width: 50%;
//             border-radius: 0;
//         }

//         div {
//             width: 50%;
//             padding: 140px 50px;

//             h2 {
//                 @include textParam($primary-color, lato, 70px, 95px, heavy);
//                 margin-bottom: 16px;
//             }

//             p {
//                 @include textParam($primary-color, lato, 18px, 32px, regular);
//                 width: 75%;
//             }

//         }

//     }

//     .statistics {
//         display: grid;
//         grid-template-columns: repeat(5, auto);
//         padding: 0 80px;
//         place-items: center;
//         width: 100%;
//         height: 150px;
//         margin-bottom: 60px;
//         background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)),
//             url('../assets/images/statisticsBackground.png');

//         .statistic {
//             // margin: 0 60px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             width: 90%;

//             .statisticIcon {
//                 width: 50px;
//                 height: 50px;
//                 margin-right: 16px;
//             }

//             h3 {
//                 @include textParam(white, lato, 20px, 28px, 900);
//             }

//             p {
//                 @include textParam(white, lato, 16px, 21px, 400);
//                 margin-top: 4px;
//             }
//         }
//     }



//     .firstCard {
//         @include cardParam(row);
//         border-radius: 30px;

//         .firstCardContent {
//             display: flex;
//             flex-direction: column;
//             padding: 70px 70px;
//             width: 50%;

//             // justify-content: space-between;
//             h2 {
//                 margin-bottom: 8px;
//                 @include textParam($secondary-color, $lato, 50px, 66px, regular)
//             }

//             h3 {
//                 @include textParam($primary-color, $lato, 32px, 40px, bold);
//                 margin-bottom: 16px;
//             }

//             p {
//                 margin-bottom: 30px;
//                 @include textParam($primary-color, $lato, 18px, 26px, regular)
//             }

//             button {
//                 @include buttonParam($primary-color, $secondary-color);
//                 width: 240px;

//                 &:hover {
//                     background-color: $button-hover-color;
//                 }
//             }

//             .newSpaces {
//                 display: grid;
//                 grid-template-columns: repeat(3, auto);
//                 width: 100%;
//                 gap: 16px;
//                 margin-top: 24px;

//                 .newSpace {
//                     // height: 85px;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     background-color: rgb(172, 169, 169);
//                     // width: 100%;
//                     padding: 24px 32px;

//                     .spaceIcon {
//                         color: black;
//                         width: 32px;
//                         height: 32px;
//                         margin-right: 8px;
//                     }

//                     div {
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: center;

//                         // height: 100px;
//                         h3 {
//                             @include textParam(white, lato, 12px, 16px, bold);
//                             margin: 0;
//                             color: black;
//                         }

//                         p {
//                             @include textParam(white, lato, 12px, 16px, 400);
//                             color: black;
//                             // margin-top: 4px;
//                             margin: 0;
//                         }
//                     }

//                 }
//             }
//         }

//         img {
//             width: 50%;
//             border-radius: 0 30px 30px 0;
//         }
//     }

//     .firstCard2 {
//         @include cardParam(row-reverse);
//         border-radius: 30px;

//         .firstCardContent {
//             display: flex;
//             flex-direction: column;
//             padding: 70px 70px;
//             width: 50%;
//             // justify-content: space-between;

//             h2 {
//                 margin-bottom: 8px;
//                 @include textParam($secondary-color, $lato, 50px, 66px, regular)
//             }

//             h3 {
//                 @include textParam($primary-color, $lato, 32px, 40px, bold);
//                 margin-bottom: 16px;
//             }

//             p {
//                 margin-bottom: 30px;
//                 @include textParam($primary-color, $lato, 18px, 26px, regular)
//             }

//             button {
//                 @include buttonParam($primary-color, $secondary-color);
//                 width: 240px;

//                 &:hover {
//                     background-color: $button-hover-color;
//                 }
//             }

//             .newSpaces {
//                 display: grid;
//                 grid-template-columns: repeat(3, auto);
//                 width: 100%;
//                 gap: 16px;
//                 margin-top: 24px;

//                 .newSpace {
//                     // height: 85px;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     background-color: rgb(172, 169, 169);
//                     // width: 100%;
//                     padding: 24px 32px;

//                     .spaceIcon {
//                         color: black;
//                         width: 32px;
//                         height: 32px;
//                         margin-right: 8px;
//                     }

//                     div {
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: center;

//                         // height: 100px;
//                         h3 {
//                             @include textParam(white, lato, 12px, 16px, bold);
//                             margin: 0;
//                             color: black;
//                         }

//                         p {
//                             @include textParam(white, lato, 12px, 16px, 400);
//                             color: black;
//                             // margin-top: 4px;
//                             margin: 0;
//                         }
//                     }

//                 }
//             }
//         }

//         img {
//             width: 50%;
//             border-radius: 30px 0 0 30px;
//         }
//     }

//     .info {
//         background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/contactBackground.jpg');
//         display: grid;
//         place-items: center;
//         padding: 200px;

//         h3 {
//             @include textParam(white, lato, 70px, 86px, 400);
//             margin-bottom: 24px;
//             text-align: center;
//         }

//         p {
//             @include textParam(white, lato, 18px, 26px, 400);
//             text-align: center;
//             width: 75%;
//             margin-bottom: 40px;
//         }

//         button {
//             @include buttonParam($primary-color, $secondary-color);
//         }
//     }


// }




// Social Event Page
.socialEvent {
    display: flex;
    flex-direction: column;

    .burna {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/burna.jpg');
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px;
        text-align: center;
        margin-top: 100px;

        h2 {
            @include textParam(white, lato, 70px, 84px, 700);
            padding-top: 50px;
            margin-bottom: 14px
        }

        h4 {
            @include textParam(white, lato, 18px, 40px, bold);
            margin-bottom: 20px;
        }

        p {
            width: 60%;
            @include textParam(white, lato, 18px, 40px, 500);
            margin-bottom: 20px;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    .subHeader {
        display: grid;
        padding-top: 50px;
        place-items: center;


        span {
            @include dashParam();
        }

        h2 {
            @include textParam($primary-color, lato, 40px, 48px, 700);
            margin-top: 16px
        }

        p {
            @include textParam($primary-color, lato, 20px, 40px, 600);
            width: 55%;
            text-align: center;
            margin-top: 16px;
            margin-bottom: 40px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(4, auto);
        margin: 0 80px;
        gap: 24px;
        // margin-top: 50px;
        justify-content: center;

        .card1 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/wedding.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card2 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/bridal.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card3 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/corporate.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card4 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/social.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card5 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/wedding.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                width: 100%;

                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                    text-align: center;
                    width: 100%
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card6 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/bridal.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card7 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/corporate.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }

        .card8 {
            background: linear-gradient(rgba(0, 43, 90, 0.4), rgba(0, 43, 90, 0.4)), url('../assets/images/social.jpg');
            height: 370px;
            padding: 40px 24px 24px 24px;
            width: 280px;
            background-size: cover;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            div {
                h3 {
                    @include textParam(white, lato, 28px, 32px, bold);
                    margin-bottom: 30px;
                }

                p {
                    @include textParam(white, lato, 18px, 28px, regular);
                    // margin-bottom: 100px;
                }
            }


            button {
                width: 100%;
                padding: 16px 0;
                border-radius: 8px;
                background-color: white;
                color: $primary-color;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;


                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }
}

@media only screen and (max-width:1200px) {
    .socialEvent {
        .burna {
            padding: 60px;

            p {
                width: 70%;
            }
        }

        .subHeader {
            p {
                width: 70%;
            }
        }

        .cards {
            margin: 0 80px;
            gap: 15px;

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card6,
            .card7,
            .card8 {
                height: 310px;
                padding: 40px 24px 24px 24px;
                width: 210px;

                div {
                    h3 {
                        @include textParam(white, lato, 24px, 28px, bold);
                    }

                    p {
                        @include textParam(white, lato, 16px, 24px, regular);
                    }
                }


                button {
                    padding: 14px 0;
                    font-size: 16px;
                }
            }

        }
    }
}

@media only screen and (max-width:1024px) {
    .socialEvent {
        .burna {
            padding: 40px;

            p {
                width: 80%;
            }
        }

        .subHeader {
            p {
                width: 80%;
            }
        }

        .cards {
            margin: 0 80px;
            gap: 10px;

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card6,
            .card7,
            .card8 {
                height: 310px;
                padding: 40px 24px 24px 24px;
                width: 210px;

                div {
                    h3 {
                        @include textParam(white, lato, 24px, 28px, bold);
                    }

                    p {
                        @include textParam(white, lato, 16px, 24px, regular);
                    }
                }


                button {
                    padding: 14px 0;
                    font-size: 16px;
                }
            }

        }
    }
}

@media only screen and (max-width:991px) {
    .socialEvent {

        .cards {
            margin: 0 80px;
            gap: 10px;

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card6,
            .card7,
            .card8 {
                height: 310px;
                padding: 30px 24px 24px 24px;
                width: 190px;

                div {
                    h3 {
                        @include textParam(white, lato, 21px, 24px, bold);
                    }

                    p {
                        @include textParam(white, lato, 14px, 20px, regular);
                    }
                }

                button {
                    padding: 12px 0;
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (max-width:820px) {
    .socialEvent {

        .burna {
            padding: 50px;

            h2 {
                @include textParam(white, lato, 32px, 39px, 700);
                padding-top: 40px;
                margin-bottom: 12px
            }

            h4 {
                @include textParam(white, lato, 12px, 20px, bold);
                margin-bottom: 16px;
            }

            p {
                width: 80%;
                @include textParam(white, lato, 12px, 20px, 500);
                margin-bottom: 20px;
            }

            button {
                // @include textParam($primary-color, lato, 12px, 28px, bold);
                border-radius: 50px;
                padding: 10px 20px;
                font-size: 12px;
            }
        }

        .subHeader {
            padding-top: 40px;

            h2 {
                @include textParam($primary-color, lato, 24px, 32px, 700);
                margin-top: 14px
            }

            p {
                @include textParam($primary-color, lato, 12px, 25px, 400);
                width: 90%;
                margin-top: 14px;
            }
        }

        .cards {
            grid-template-columns: repeat(2, auto);

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card6,
            .card7,
            .card8 {

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 22px, bold);
                    }

                    p {
                        @include textParam(white, lato, 12px, 15px, regular);
                    }
                }

                button {
                    padding: 10px 0;
                    font-size: 11px;
                }
            }
        }
    }
}

@media only screen and (max-width:520px) {
    .socialEvent {

        .burna {
            margin-top: 80px;

            p {
                width: 120%;
            }

            button {
                padding: 8px 16px;
                font-size: 10px;
            }
        }

        .subHeader {
            p {
                width: 90%;
                font-weight: 400;
            }
        }

        .cards {
            gap: 24px;

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card6,
            .card7,
            .card8 {
                height: 200px;
                padding: 20px 14px;
                width: 150px;

                div {
                    h3 {
                        @include textParam(white, lato, 18px, 22px, bold);
                        // width: 100%;
                        // text-align: center
                    }

                    p {
                        @include textParam(white, lato, 12px, 15px, regular);
                    }
                }
            }
        }
    }
}





.weddingEvent {
    display: flex;
    flex-direction: column;

    .burna {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/wedding2.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 80px;
        text-align: center;
        margin-top: 100px;

        h2 {
            @include textParam(white, lato, 70px, 84px, 700);
            margin-bottom: 16px
        }

        p {
            width: 75%;
            @include textParam(white, lato, 18px, 40px, 500);
            margin-bottom: 16px;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    .subHeader {
        display: grid;
        padding: 100px 0;
        place-items: center;


        span {
            @include dashParam();
        }

        h2 {
            @include textParam($primary-color, lato, 40px, 48px, 700);
            margin-top: 16px
        }

        p {
            @include textParam($primary-color, lato, 20px, 40px, 600);
            width: 65%;
            text-align: center;
            margin-top: 16px;
            margin-bottom: 40px;
        }
    }

    .weddingCards {
        display: grid;
        margin: 0 80px;
        grid-template-columns: repeat(2, auto);
        gap: 60px;
        justify-content: center;
        align-items: center;

        .weddingCard {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0 3px 35px 0 rgba(0, 0, 0, 0.08);

            h3 {
                text-align: center;
                @include textParam($secondary-color, $alexBrush, 50px, 66px, 400);
                margin: 0;
                margin: 24px 0;
            }

            p {
                @include textParam($primary-color, lato, 18px, 40px, 500);
                padding: 0 40px 40px;
            }
        }

    }

    .slideshow {
        display: flex;
        flex-direction: column;
        margin: 70px 0;
        width: 100%;

        h3 {
            @include textParam($primary-color, lato, 40px, 48px, 500);
            margin-bottom: 32px;
            text-align: center;
        }

        .slides {
            display: grid;
            grid-template-columns: repeat(3, auto);
            margin: 0 80px;
            height: 280px;
            // width: 100%;
            column-gap: 24px;

            .slide1 {
                background-image: url('../assets/images/slide1.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: center;
            }

            .slide2 {
                background-image: url('../assets/images/slide2.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: center;
            }

            .slide3 {
                background-image: url('../assets/images/slide3.jpg');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: center;
            }

        }

        .slidesSmallscreen {
            display: flex
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            // @include textParam($primary-color, lato, 23px, 28px, 700);
            display: grid;
            place-self: center;
            margin-top: 50px;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .weddingEvent {
        .burna {
            padding: 50px 10px;

            p {
                width: 85%;
            }
        }

        .subHeader {
            p {
                width: 75%;
            }
        }

        .weddingCards {
            margin: 0 60px;
            gap: 30px;

            h3 {
                margin: 20px 0;
            }

            p {
                padding: 0 40px 30px;
            }
        }

        .slideshow {
            .slides {
                margin: 0 60px;
                height: 280px;
                // width: 100%;
                column-gap: 16px;
            }
        }
    }
}

@media only screen and (max-width:850px) {
    .weddingEvent {
        .burna {
            p {
                width: 90%;
            }
        }

        .subHeader {
            p {
                width: 80%;
            }
        }

        .weddingCards {
            margin: 0 20px;
            gap: 10px;

            .weddingCard {
                width: 280px;
                height: 100%;

                h3 {
                    @include textParam($secondary-color, $alexBrush, 42px, 48px, 400);
                }

                p {
                    @include textParam($primary-color, lato, 16px, 30px, 500);
                    padding: 0 30px 40px;
                }
            }

        }

        .slideshow {
            .slides {
                margin: 0 40px;
                column-gap: 12px;
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .weddingEvent {

        .burna {
            padding: 40px 16px;
            margin-top: 80px;

            h2 {
                @include textParam(white, lato, 32px, 38px, 700);
                margin-bottom: 12px
            }

            p {
                width: 100%;
                @include textParam(white, lato, 12px, 20px, 400);
                margin-bottom: 24px;
            }

            button {
                // @include textParam($primary-color, lato, 12px, 28px, 700);
                padding: 8px 16px;
                font-size: 10px;
                border-radius: 50px;
            }
        }

        .subHeader {
            padding-top: 0;
            padding-top: 40px;
            margin: 0;

            span {
                @include dashParam();
                // margin-bottom: 14px;
            }

            h2 {
                @include textParam($primary-color, lato, 24px, 32px, bold);
                margin-top: 14px
            }

            p {
                @include textParam($primary-color, lato, 12px, 25px, 400);
                width: 90%;
                margin-top: 14px;
                margin-bottom: 0;
            }
        }

        .weddingCards {
            padding: 0;
            margin: 0;
            margin-top: 24px;
            margin: 0 20px;
            grid-template-columns: repeat(1, auto);
            gap: 30px;

            .weddingCard {
                width: 300px;
                // margin-bottom: 24px; 

                h3 {
                    text-align: center;
                    @include textParam($secondary-color, $alexBrush, 24px, 30px, 400);
                    margin: 18px 0;
                }

                p {
                    @include textParam($primary-color, lato, 12px, 25px, 500);
                }
            }

        }

        .slideshow {
            margin: 0;
            margin-top: 40px;
            width: 100%;

            h3 {
                @include textParam($primary-color, lato, 24px, 32px, 700);
                margin-bottom: 30px;
            }

            .slides {
                display: none;
            }

            .slidesSmallscreen {
                display: flex;
                height: 400px;
            }

            button {
                // @include textParam($primary-color, lato, 12px, 28px, 700);
                margin-top: 40px;
                border-radius: 25px;
                padding: 8px 16px;
                font-size: 10px;
            }
        }
    }
}



// Corporate Event Page
.corporateEvent {
    display: flex;
    flex-direction: column;

    .corporate {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/corporate2.jpg');
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 80px;
        text-align: center;
        margin-top: 100px;

        h2 {
            @include textParam(white, lato, 70px, 84px, 700);
            margin-bottom: 14px;
        }

        h4 {
            @include textParam(white, lato, 18px, 40px, bold);
            margin-bottom: 24px;
        }

        p {
            width: 55%;
            @include textParam(white, lato, 18px, 40px, 500);
            margin-bottom: 20px;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-bottom: 20px;

            &:hover {
                background-color: $button-hover-color
            }
        }
    }

    .subHeader {
        display: grid;
        padding-top: 50px;
        place-items: center;
        justify-content: center;

        h2 {
            @include textParam($primary-color, lato, 32px, 40px, 700);
            text-transform: uppercase;
            margin-top: 16px
        }

        p {
            @include textParam($primary-color, lato, 20px, 40px, 600);
            width: 65%;
            text-align: left;
            margin-top: 16px;
            margin-bottom: 40px;
        }
    }

    .corporateTypes {
        display: grid;
        place-items: center;
        grid-template-columns: repeat(2, auto);
        gap: 40px;
        margin-top: 60px;
        margin-bottom: 100px;
        justify-content: center;


        .corporateType {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .corporateIcon {
                margin-right: 16px;
            }

            p {
                width: 100%;
                margin: 0;
            }
        }
    }

    .secondCard {
        @include cardParam(row-reverse);
        border-radius: 30px 30px 30px 30px;
        border: 1px solid $secondary-color;
        position: relative;

        .secondCardContent {
            display: flex;
            flex-direction: column;
            padding: 40px 50px;
            justify-content: center;
            width: 65%;

            span {
                @include dashParam();
                margin-bottom: 16px;
            }

            h2 {
                margin-bottom: 24px;
                @include textParam($primary-color, $lato, 40px, 48px, 700);
            }

            p {
                margin-bottom: 27px;
                @include textParam($primary-color, $lato, 20px, 40px, 600)
            }

            div {
                display: flex;

                button {
                    &.secondaryBtn {
                        @include buttonParam($button-hover-color, white);
                        border: 2px solid $button-hover-color;

                        &:hover {
                            background-color: $button-hover-color;
                            color: white;
                        }
                    }
                }
            }
        }

        img {
            width: 35%;
            border-radius: 30px 0 0 30px;
            border: 0;
        }
    }
}

@media only screen and (max-width:1024px) {
    .corporateEvent {
        .corporate {
            padding: 140px 50px;

            p {
                width: 80%;
            }

            button {
                padding: 15px 40px;
            }
        }

        .corporateTypes {
            gap: 30px;
            margin-top: 50px;

            .corporateType {

                .corporateIcon {
                    margin-right: 14px;
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .secondCard {

            .secondCardContent {
                padding: 40px 30px;
                width: 60%;

                h2 {
                    @include textParam($primary-color, $lato, 38px, 45px, 700);
                }

                p {
                    @include textParam($primary-color, $lato, 18px, 40px, 600)
                }
            }

            img {
                width: 40%;
            }
        }
    }
}

@media only screen and (max-width:962px) {
    .corporateEvent {
        .corporateTypes {
            grid-template-columns: repeat(1, auto);

            .corporateType {
                .corporateIcon {
                    margin-right: 12px;
                    width: 36px;
                    height: 36px;
                }
            }
        }

        .secondCard {
            .secondCardContent {
                width: 50%;

                h2 {
                    @include textParam($primary-color, $lato, 36px, 42px, 700);
                }

                p {
                    @include textParam($primary-color, $lato, 18px, 40px, 600)
                }
            }

            img {
                width: 50%;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .corporateEvent {
        .corporate {
            padding: 120px 50px;
        }

        .corporateTypes {
            grid-template-columns: repeat(1, auto);
        }

        .secondCard {
            flex-direction: column;
            border-radius: 30px 30px 0 0;

            .secondCardContent {
                width: 90%;
            }

            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .corporateEvent {

        .corporate {
            padding: 40px;
            margin-top: 80px;

            h2 {
                @include textParam(white, lato, 32px, 39px, 700);
                margin-bottom: 16px;
            }

            h4 {
                @include textParam(white, lato, 18px, 20px, medium);
                margin-bottom: 16px;
            }

            p {
                width: 100%;
                @include textParam(white, lato, 12px, 20px, 500);
                margin-bottom: 20px;
            }

            button {
                // @include textParam($primary-color, lato, 12px, 28px, 700);
                padding: 8px 16px;
                font-size: 10px;
                border-radius: 50px;
                margin-bottom: 10px;
            }
        }

        .subHeader {
            margin: 0 16px;
            text-align: center;

            h2 {
                @include textParam($primary-color, lato, 24px, 32px, 700);
                margin: 0;
            }


            .corporateTypes {
                margin: 0;
                margin: 24px 0 40px;

                .corporateType {
                    .corporateIcon {
                        margin-right: 10px;
                        width: 15px;
                        height: 20px;
                    }

                    p {
                        @include textParam($primary-color, lato, 14px, 20px, 400);
                    }
                }
            }
        }


        .secondCard {
            margin-top: 0;
            margin-bottom: 0;
            border-bottom-left-radius: 30px;

            .secondCardContent {
                h2 {
                    @include textParam($primary-color, $lato, 22px, 27px, 700);
                }

                p {
                    @include textParam($primary-color, $lato, 12px, 24px, 400)
                }

                div {
                    button {
                        &.secondaryBtn {
                            // @include textParam($button-hover-color, $lato, 12px, 28px, 700);
                            padding: 8px 16px;
                            border-radius: 50px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}



// Same styles as wosamApartments

.wosamHotels,
.wosamApartments {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/Wosam Hotel rooms.png');
        background-position-x: center;
        padding: 60px 60px 40px 80px;
        background-size: cover;
        margin-bottom: 60px;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, $lato, 18px, 40px, bold);
            margin-bottom: 12px;
        }

        h1 {
            @include textParam(white, $lato, 70px, 84px, bold);
            width: 70%;
        }

        p {
            @include textParam(white, $lato, 18px, 30px, regular);
            width: 55%;
        }

        form {
            margin-top: 40px;
            display: flex;
            padding: 30px;
            place-self: center;
            background-color: white;
            column-gap: 30px;
            border-radius: 16px;
            justify-content: flex-end;
            align-items: flex-end;
            justify-content: flex-end;
            display: flex;

            div {
                display: flex;
                flex-direction: column;

                label {
                    @include textParam($primary-color, $lato, 18px, 40px, bold);
                }

                .calendarContainer {
                    position: relative;
                    width: 250px;
                    display: flex;
                    align-items: center;

                    input {
                        width: 100%;
                        padding: 16px 16px;
                        border-radius: 8px;
                        border: 1px solid $primary-color;
                        color: $primary-color;
                        font-size: 16px;
                        outline: 0;


                        &::placeholder {
                            color: $primary-color;
                            font-size: 16px;
                        }
                    }

                    .calendarIcon {
                        position: absolute;
                        right: 16px;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                }
            }

            button {
                @include buttonParam(white, $primary-color);
                padding: 16px 32px;
                border-radius: 8px;
                font-size: 16px;
                border: 0;
            }
        }
    }

    .subHeader {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            @include textParam($primary-color, $lato, 18px, 40px, bold);
        }

        h2 {
            @include textParam($primary-color, $lato, 40px, 40px, bold);
            margin-top: 8px;
        }

        .features {
            width: 80%;
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            gap: 40px;
            // justify-content: center;
            margin: 40px 80px;

            .feature {
                background-color: #F5F5F5;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 24px;
                border-radius: 8px;
                // margin: 0 15px;

                .moreIcon {
                    // display: flex;
                    // place-content: center;
                    margin-top: 32px;
                }

                p {
                    @include textParam($primary-color, $lato, 18px, 18px, bold);
                    margin-top: 16px;
                }
            }
        }
    }

    .video {
        background-image: url('../assets/images/HotelImage.jpg');
        background-size: cover;
        width: 80%;
        margin: 80px 10vw;
        background-position: center;
        border-radius: 35px;
        height: 580px;
        cursor: pointer;
    }

    .ourRooms {
        display: flex;
        flex-direction: column;
        margin: 40px 0;
        align-items: center;

        h2 {
            @include textParam(#001934, $lato, 60px, 40px, 700);
            text-align: center;
        }

        .rooms {
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 30px;
            row-gap: 50px;
            margin: 60px 80px 0px;

            .room {
                padding: 14px;
                display: flex;
                column-gap: 14px;
                width: 580px;
                border: 1px solid $primary-color;
                align-items: center;
                border-radius: 8px;

                img {
                    object-fit: cover;
                    width: 200px;
                    height: 200px;
                    border-radius: 5px;
                }

                .contents {
                    display: flex;
                    flex-direction: column;

                    .star {
                        display: flex;
                        column-gap: 4px;
                        margin-bottom: 14px;
                    }

                    h4 {
                        @include textParam(#001934, $lato, 28px, 40px, 600);
                        display: flex;
                        align-items: center;
                        column-gap: 5px;

                        span {
                            @include textParam(#001934, $lato, 18px, 40px, 600);
                        }
                    }

                    h3 {
                        @include textParam(#001934, $lato, 26px, 40px, bold);
                        margin-top: 5px;
                        margin-bottom: 16px;
                    }

                    .buttonDiv {
                        display: flex;

                        .primaryBtn {
                            @include buttonParam(#001934, white);
                            @include textParam(#001934, $lato, 18px, 30px, bold);
                            border-radius: 5px;
                            border: 2px solid $primary-color;
                            padding: 8px 16px;
                            margin-right: 16px;
                        }

                        .secondaryBtn {
                            @include buttonParam(white, #001934);
                            @include textParam(#fff, $lato, 18px, 30px, bold);
                            border-radius: 5px;
                            padding: 8px 24px;
                        }
                    }
                }
            }
        }
    }
}

.wosamApartments {
    header {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/threeBedroomBg.png');
    }
}

@media only screen and (max-width:1200px) {

    .wosamHotels,
    .wosamApartments {
        .ourRooms {
            .rooms {
                column-gap: 20px;
                row-gap: 30px;
                margin: 60px 40px 0px;

                .room {
                    width: 450px;
                    padding: 10px;

                    img {
                        width: 200px;
                        height: 200px;
                    }

                    .contents {
                        display: flex;
                        flex-direction: column;

                        .star {
                            column-gap: 3px;
                            margin-bottom: 5px;
                        }

                        h4 {
                            @include textParam(#001934, $lato, 20px, 30px, 600);

                            span {
                                @include textParam(#001934, $lato, 15px, 30px, 600);
                            }
                        }

                        h3 {
                            @include textParam(#001934, $lato, 18px, 30px, bold);
                            margin-top: 4px;
                            margin-bottom: 14px;
                        }

                        .buttonDiv {
                            .primaryBtn {
                                @include textParam(#001934, $lato, 14px, 20px, bold);
                                margin-right: 10px;
                                padding: 8px 16px;
                            }

                            .secondaryBtn {
                                @include textParam(#fff, $lato, 14px, 20px, bold);
                                padding: 8px 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:991px) {

    .wosamHotels,
    .wosamApartments {
        header {
            padding: 60px 40px;

            h3 {
                @include textParam(white, $lato, 16px, 30px, bold);
                margin-bottom: 12px;
            }

            h1 {
                @include textParam(white, $lato, 50px, 60px, bold);
                width: 80%;
            }

            p {
                @include textParam(white, $lato, 16px, 25px, regular);
                width: 70%;
            }

            form {
                margin-top: 35px;
                padding: 25px;
                column-gap: 15px;
                justify-content: flex-end;
                align-items: flex-end;
                justify-content: flex-end;

                div {
                    display: flex;
                    flex-direction: column;

                    label {
                        @include textParam($primary-color, $lato, 18px, 40px, bold);
                    }

                    .calendarContainer {
                        position: relative;
                        width: 250px;
                        display: flex;
                        align-items: center;

                        input {
                            width: 100%;
                            padding: 16px 16px;
                            border-radius: 8px;
                            border: 1px solid $primary-color;
                            color: $primary-color;
                            font-size: 16px;
                            outline: 0;


                            &::placeholder {
                                color: $primary-color;
                                font-size: 16px;
                            }
                        }

                        .calendarIcon {
                            position: absolute;
                            right: 16px;
                            transform: translateY(-50%);
                            top: 50%;
                        }
                    }
                }

                button {
                    @include buttonParam(white, $primary-color);
                    padding: 16px 32px;
                    border-radius: 8px;
                    font-size: 16px;
                    border: 0;
                }
            }
        }

        .subHeader {
            h3 {
                @include textParam($primary-color, $lato, 16px, 30px, bold);
            }

            h2 {
                @include textParam($primary-color, $lato, 30px, 30px, bold);
                margin-top: 6px;
            }

            .features {
                margin: 30px;

                .feature {
                    padding: 24px;
                    margin: 0 14px;

                    p {
                        @include textParam($primary-color, $lato, 16px, 16px, bold);
                    }
                }
            }
        }

        .video {
            width: 90%;
            height: 500px;
        }

        .ourRooms {
            .rooms {
                grid-template-columns: repeat(1, auto);
                row-gap: 40px;
                margin: 60px 40px 0px;

                .room {
                    width: 500px;

                    img {
                        width: 180px;
                        height: 180px;
                    }

                    .contents {
                        .star {
                            column-gap: 4px;
                            margin-bottom: 8px;
                        }

                        h3 {
                            margin-top: 5px;
                            margin-bottom: 16px;
                        }

                        .buttonDiv {
                            .primaryBtn {
                                padding: 8px 16px;
                            }

                            .secondaryBtn {
                                padding: 8px 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {

    .wosamHotels,
    .wosamApartments {
        header {
            padding: 40px 32px;

            h3 {
                @include textParam($secondary-color, $lato, 16px, 20px, bold);
                margin-bottom: 4px;
            }

            h1 {
                @include textParam(white, $lato, 32px, 38px, bold);
                width: 90%;
                margin-bottom: 8px
            }

            p {
                @include textParam(white, $lato, 14px, 25px, regular);
                width: 80%;
            }

            form {
                width: 100%;
                padding: 16px;
                column-gap: 15px;
                flex-direction: column;
                align-items: center;

                div {
                    width: 100%;

                    label {
                        @include textParam($primary-color, $lato, 14px, 30px, bold);
                        margin-top: 5px;
                    }

                    .calendarContainer {
                        width: 100%;

                        input {
                            padding: 10px;
                            border-radius: 5px;
                            font-size: 14px;

                            &::placeholder {
                                font-size: 14px;
                            }
                        }

                        .calendarIcon {
                            right: 12px;
                            top: 50%;
                        }
                    }
                }

                button {
                    width: 100%;
                    padding: 10px 20px;
                    border-radius: 5px;
                    font-size: 14px;
                    margin: 24px 16px
                }
            }
        }

        .subHeader {
            width: 100%;

            h3 {
                @include textParam($primary-color, $lato, 14px, 25px, bold);
            }

            h2 {
                @include textParam($primary-color, $lato, 24px, 30px, bold);
            }

            .features {
                display: grid;
                grid-template-columns: repeat(3, auto);
                margin: 16px 30px;

                .feature {
                    padding: 16px;
                    margin: 8px;

                    p {
                        @include textParam($primary-color, $lato, 14px, 14px, bold);
                    }
                }
            }
        }

        .video {
            height: 400px;
            margin-top: 40px;
            margin-bottom: 0;
        }

        .ourRooms {
            margin: 40px 0 40px;

            h2 {
                @include textParam(#001934, $lato, 30px, 30px, 700);
            }

            .rooms {
                row-gap: 20px;
                margin: 30px 20px;

                .room {
                    width: 100%;

                    img {
                        width: 35%;
                        height: 180px;
                    }

                    .contents {
                        .star {
                            column-gap: 4px;
                            margin-bottom: 6px;
                        }

                        h4 {
                            @include textParam(#001934, $lato, 16px, 24px, 600);

                            span {
                                @include textParam(#001934, $lato, 13px, 24px, 600);
                            }
                        }

                        h3 {
                            @include textParam(#001934, $lato, 16px, 24px, bold);
                            margin-top: 6px;
                            margin-bottom: 16px;
                        }

                        .buttonDiv {
                            display: flex;
                            column-gap: 8px;

                            .primaryBtn {
                                // padding: 8px 16px;
                            }

                            .secondaryBtn {
                                // padding: 8px 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:520px) {

    .wosamHotels,
    .wosamApartments {
        header {
            padding: 30px 10px 30px 20px;
            margin-top: 80px;

            h1 {
                width: 92%;
            }

            p {
                width: 90%;
            }
        }

        .subHeader {
            .features {
                display: grid;
                grid-template-columns: repeat(2, auto);
                gap: 24px;
                margin: 16px 24px;

                .feature {
                    padding: 24px;

                    p {
                        @include textParam($primary-color, $lato, 12px, 14px, bold);
                    }
                }
            }
        }

        .video {
            margin: 40px auto;
            height: 400px;
        }

        .ourRooms {
            margin: 30px 10px 32px;

            h2 {
                @include textParam(#001934, $lato, 30px, 30px, 700);
            }

            .rooms {
                margin: 30px 0px 0px;
                display: grid;
                grid-template-columns: repeat(1, auto);
                gap: 32px;

                .room {
                    width: 100%;
                    gap: 8px;

                    img {
                        width: 80px;
                        height: 80px;
                    }

                    h4 {
                        @include textParam(#001934, $lato, 14px, 20px, 600);

                        span {
                            @include textParam(#001934, $lato, 12px, 20px, 600);
                        }
                    }

                    h3 {
                        @include textParam(#001934, $lato, 14px, 20px, bold);
                        margin-top: 4px;
                        margin-bottom: 8px;
                    }

                    .buttonDiv {
                        gap: 4px;

                        .primaryBtn {
                            // @include textParam(#001934, $lato, 12px, 16px, bold);
                            font-size: 8px;
                            display: inline-block;
                            width: 80px;
                            height: 40px;
                            margin-right: 0;
                        }

                        .secondaryBtn {
                            // @include textParam(#fff, $lato, 12px, 16px, bold);
                            margin-left: 0;
                            display: inline-block;
                            width: 80px;
                            height: 40px;
                            font-size: 8px;

                        }
                    }
                }
            }
        }
    }
}



// Same styles as wosamApartments

@media only screen and (max-width:768px) {

    .wosamApartments {
        header {
            padding: 40px 32px;

            h3 {
                @include textParam($secondary-color, $lato, 16px, 20px, bold);
                margin-bottom: 4px;
            }

            h1 {
                @include textParam(white, $lato, 32px, 38px, bold);
                width: 90%;
                margin-bottom: 8px
            }

            p {
                @include textParam(white, $lato, 14px, 25px, regular);
                width: 80%;
            }

            form {
                width: 100%;
                padding: 16px;
                column-gap: 15px;
                flex-direction: column;
                align-items: center;

                div {
                    width: 100%;

                    label {
                        @include textParam($primary-color, $lato, 14px, 30px, bold);
                        margin-top: 5px;
                    }

                    .calendarContainer {
                        width: 100%;

                        input {
                            padding: 10px;
                            border-radius: 5px;
                            font-size: 14px;

                            &::placeholder {
                                font-size: 14px;
                            }
                        }

                        .calendarIcon {
                            right: 12px;
                            top: 50%;
                        }
                    }
                }

                button {
                    width: 100%;
                    padding: 10px 20px;
                    border-radius: 5px;
                    font-size: 14px;
                    margin: 24px 16px
                }
            }
        }

        .subHeader {
            width: 100%;

            h3 {
                @include textParam($primary-color, $lato, 12px, 25px, bold);
            }

            h2 {
                @include textParam($primary-color, $lato, 22px, 30px, bold);
            }

            .features {
                display: grid;
                // place-items: center;
                grid-template-columns: repeat(2, auto);
                gap: 16px;
                margin: 24px;

                .feature {
                    padding: 24px;

                    p {
                        @include textParam($primary-color, $lato, 12px, 14px, bold);
                    }
                }
            }
        }

        .ourRooms {
            margin: 24px 0 0px;

            h2 {
                @include textParam(#001934, $lato, 24px, 30px, 700);
            }

            .rooms {
                display: grid;
                grid-template-columns: repeat(1, auto);
                row-gap: 20px;
                margin: 30px 16px;

                .room {
                    width: 100%;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: auto;
                    padding: 8px;

                    img {
                        // width: 35%;
                        height: 130px;
                        flex: 1;

                    }

                    .contents {
                        flex: 2;
                        // align-items: center;

                        .star {
                            column-gap: 4px;
                            margin-bottom: 4px;
                        }

                        h4 {
                            @include textParam(#001934, $lato, 18px, 30px, 700);
                            margin-bottom: 0;
                        }

                        h3 {
                            @include textParam(#001934, $lato, 12px, 20px, 700);
                            margin-top: 5px;
                            margin-bottom: 8px;
                        }

                        .buttonDiv {
                            display: flex;
                            column-gap: 4px;

                            .primaryBtn {
                                padding: 4px 8px;
                                font-size: 10px;
                            }

                            .secondaryBtn {
                                font-size: 10px;
                                padding: 4px 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}




.ourTeamPage {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .ourTeam {
        background: linear-gradient(rgba(0, 43, 90, 0.5), rgba(0, 43, 90, 0.5)), url('../assets/images/MUFU ADEKOYA WOSAM.png') no-repeat center center/cover;
        display: grid;
        place-items: center;
        // height: 430px;
        width: 100%;
        padding: 100px;
        margin-bottom: 40px;
        margin-top: 100px;

        h2 {
            @include textParam(white, lato, 70px, 95px, heavy);
            margin-bottom: 30px
        }

        p {
            @include textParam(white, lato, 24px, 40px, medium);
            width: 80%;
            text-align: center;
        }
    }

    .board,
    .board2 {
        display: flex;
        flex-direction: column;
        margin: 0px 0 80px;

        .title {
            // text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                @include dashParam();
                display: flex;
                margin-bottom: 12px;
            }

            h2 {
                @include textParam($primary-color, lato, 51px, 68px, bold);
            }

            h3 {
                @include textParam($primary-color, lato, 30px, 40px, 300);

            }

            .directors {
                display: grid;
                grid-template-columns: repeat(3, auto);
                column-gap: 40px;
                row-gap: 40px;
                margin: 40px 120px;
                margin-bottom: 24px;
                // justify-content: space-between;

                .director {
                    border: 1px solid $primary-color;
                    padding: 16px;
                    text-align: left;
                    border-radius: 8px;
                    width: 350px;
                    height: 400px;

                    h4 {
                        @include textParam($primary-color, lato, 28px, 37px, 600);
                    }

                    h5 {
                        @include textParam($secondary-color, lato, 24px, 32px, 700);

                    }
                }
            }
        }
    }

    .board2 {
        margin-bottom: 0px;
    }

}

@media only screen and (max-width:1280px) {
    .ourTeamPage {

        .ourTeam {
            margin-bottom: 90px;

            h2 {
                @include textParam(white, lato, 70px, 95px, heavy);
                margin-bottom: 25px
            }

            p {
                @include textParam(white, lato, 24px, 40px, medium);
                width: 90%;
                text-align: center;
            }
        }

        .board,
        .board2 {
            .title {
                h2 {
                    @include textParam($primary-color, lato, 51px, 68px, bold);
                }

                h3 {
                    @include textParam($primary-color, lato, 30px, 40px, 300);

                }

                .directors {
                    margin: 40px 30px;
                    column-gap: 50px;
                    row-gap: 30px;

                    .director {
                        padding: 16px;
                        width: 300px;
                        height: 350px;

                        img {
                            width: 100%
                        }

                        h4 {
                            @include textParam($primary-color, lato, 28px, 37px, 600);
                        }

                        h5 {
                            @include textParam($secondary-color, lato, 24px, 32px, 700);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .ourTeamPage {

        .ourTeam {
            margin-bottom: 90px;

            h2 {
                @include textParam(white, lato, 67px, 90px, heavy);
                margin-bottom: 25px
            }

            p {
                @include textParam(white, lato, 22px, 37px, medium);
                width: 95%;
            }
        }

        .board,
        .board2 {
            .title {
                h2 {
                    @include textParam($primary-color, lato, 51px, 68px, bold);
                }

                h3 {
                    @include textParam($primary-color, lato, 30px, 40px, 300);

                }

                .directors {
                    margin: 40px 20px;
                    column-gap: 20px;

                    .director {
                        padding: 14px;
                        width: 250px;
                        height: 300px;

                        h4 {
                            @include textParam($primary-color, lato, 24px, 32px, 600);
                        }

                        h5 {
                            @include textParam($secondary-color, lato, 21px, 28px, 700);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:991px) {
    .ourTeamPage {
        .ourTeam {
            p {
                width: 100%;
            }
        }

        .board,
        .board2 {
            .title {
                h2 {
                    @include textParam($primary-color, lato, 40px, 50px, bold);
                }

                h3 {
                    @include textParam($primary-color, lato, 20px, 30px, 300);

                }

                .directors {
                    .director {
                        width: 200px;
                        height: 250px;

                        h4 {
                            @include textParam($primary-color, lato, 24px, 32px, 600);
                        }

                        h5 {
                            @include textParam($secondary-color, lato, 21px, 28px, 700);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .ourTeamPage {

        .ourTeam {
            margin-bottom: 24px;

            h2 {
                @include textParam(white, lato, 65px, 85px, heavy);
                margin-bottom: 20px
            }

            p {
                @include textParam(white, lato, 20px, 35px, medium);
            }
        }

        .board,
        .board2 {
            .title {
                h2 {
                    @include textParam($primary-color, lato, 40px, 50px, bold);
                }

                h3 {
                    @include textParam($primary-color, lato, 20px, 30px, 300);

                }

                .directors {
                    .director {
                        width: 180px;
                        height: 220px;

                        h4 {
                            @include textParam($primary-color, lato, 21px, 28px, 600);
                        }

                        h5 {
                            @include textParam($secondary-color, lato, 18px, 24px, 700);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:540px) {
    .ourTeamPage {
        margin-bottom: 0px;
        // margin-top: 80px;

        .ourTeam {
            width: 100%;
            padding: 70px 30px;
            margin-bottom: 40px;
            margin-top: 80px;

            h2 {
                @include textParam(white, lato, 32px, 39px, heavy);
                margin-bottom: 25px
            }

            p {
                @include textParam(white, lato, 12px, 20px, medium);
            }
        }

        .board,
        .board2 {
            margin-bottom: 0;
            margin-bottom: 30px;

            .title {
                span {
                    margin-bottom: 14px;
                }

                // margin: 0;
                h2 {
                    @include textParam($primary-color, lato, 24px, 32px, bold);
                    // margin-top: 4px;
                }

                h3 {
                    @include textParam($primary-color, lato, 18px, 28px, regular);

                }

                .directors {
                    grid-template-columns: repeat(2, auto);
                    // width: 100%;
                }
            }
        }

        .board2 {
            margin-bottom: 0px;
        }
    }
}

@media only screen and (max-width:410px) {
    .ourTeamPage {

        .board,
        .board2 {
            .title {
                .directors {
                    grid-template-columns: repeat(1, auto);
                }
            }
        }
    }
}

.wosamCafe {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/wosam cafe.png');
        padding: 80px 0;
        color: white;
        text-align: center;
        background-size: cover;
        background-position-x: center;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, lato, 18px, 40px, bold);
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 53%;
        }

        p {
            @include textParam(white, lato, 18px, 32px, 400);
            width: 70%;
            margin-bottom: 24px
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-bottom: 20px;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        // margin-top: 80px;
        @include textParam($primary-color, lato, 60px, 40px, bold);
        text-align: center;
        margin: 0
    }

    .firstCard {
        @include cardParam(row);
        border-radius: 30px;
        margin-top: 80px;

        .firstCardContent {
            display: flex;
            flex-direction: column;
            padding: 50px;
            width: 50%;

            // justify-content: space-between;
            h2 {
                margin-bottom: 8px;
                @include textParam($secondary-color, $alexBrush, 50px, 66px, regular);
                text-align: left;
            }

            h3 {
                @include textParam($primary-color, $lato, 32px, 40px, bold);
                margin-bottom: 16px;
            }

            p {
                margin: 0;
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 26px, regular);
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                margin-bottom: 20px;
                width: 38%;

                &:hover {
                    background-color: $button-hover-color;
                }
            }

            .newSpaces {
                display: grid;
                grid-template-columns: repeat(3, auto);
                width: 100%;
                gap: 16px;
                margin-top: 24px;

                .newSpace {
                    // height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(48, 87, 129, 0.05);
                    width: 100%;
                    padding: 24px 16px;
                    border-radius: 8px;

                    .spaceIcon {
                        color: black;
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        // height: 100px;
                        h3 {
                            @include textParam(white, lato, 12px, 28px, 900);
                            margin: 0;
                            color: black;
                        }

                        p {
                            @include textParam(white, lato, 12px, 16px, 400);
                            color: black;
                            // margin-top: 4px;
                            margin: 0;
                        }
                    }

                }
            }
        }

        img {
            width: 50%;
            border-radius: 0 30px 30px 0;
        }
    }
}

@media only screen and (max-width:1024px) {
    .wosamCafe {
        header {
            h3 {
                @include textParam(white, lato, 16px, 36px, bold);
            }

            h1 {
                @include textParam(white, lato, 55px, 70px, bold);
                width: 65%;
            }

            p {
                @include textParam(white, lato, 16px, 28px, medium);
                width: 75%;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 23px, 28px, 700);
                padding: 16px 40px;
                border-radius: 50px;
                margin-bottom: 20px;
            }
        }

        p {
            margin: 40px 0;
            @include textParam($primary-color, lato, 16px, 21px, bold);
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 50px, 36px, bold);
        }

        .firstCard {
            .firstCardContent {
                padding: 30px;
                width: 50%;

                h2 {
                    @include textParam($secondary-color, $lato, 40px, 60px, regular);
                }

                h3 {
                    @include textParam($primary-color, $lato, 28px, 36px, bold);
                    margin-bottom: 15px;
                }

                p {
                    @include textParam($primary-color, $lato, 16px, 24px, regular);
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 28px, 32px, bold);
                    padding: 16px 40px;
                    border-radius: 50px;
                    margin-bottom: 20px;
                    width: 240px;
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;
                        border-radius: 8px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 50%;
                border-radius: 0 30px 30px 0;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .wosamCafe {
        header {
            padding: 40px 24px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
                @include textParam(white, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 36px, 45px, 700);
                width: 80%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 16px, 20px, 700);
                border-radius: 50px;
                margin-bottom: 20px;
                padding: 10px 20px;
            }
        }

        p {
            margin: 24px 0 16px;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            @include textParam($primary-color, lato, 34px, 40px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 24px 40px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 32px, 40px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 20px, 28px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 14px, 21px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 20px, 24px, bold);
                    width: 150px;
                    padding: 10px 16px;
                    margin-bottom: 16px;
                    border-radius: 25px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

@media only screen and (max-width:520px) {
    .wosamCafe {
        header {
            padding: 40px 20px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            h3 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 100%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                padding: 4px 16px;
                font-size: 10px;
            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }


        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 24px, 32px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 20px 20px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 20px, 32px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 16px, 24px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 12px, 20px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    width: 100px;
                    padding: 4px 16px;
                    margin-bottom: 16px;
                    font-size: 10px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 14px;
                    margin-top: 24px;

                    .newSpace {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(48, 87, 129, 0.05);
                        padding: 20px;

                        .spaceIcon {
                            color: black;
                            width: 30px;
                            height: 30px;
                            margin-right: 7px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

.wosamLounge {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/Wosam Lounge Chill spot.png');
        padding: 80px 0;
        color: white;
        text-align: center;
        background-size: cover;
        background-position-x: center;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, lato, 18px, 40px, bold);
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 53%;
        }

        p {
            @include textParam(white, lato, 18px, 32px, 400);
            width: 70%;
            margin-bottom: 24px
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-bottom: 20px;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        // margin-top: 80px;
        @include textParam($primary-color, lato, 60px, 40px, bold);
        text-align: center;
        margin: 0
    }

    .firstCard {
        @include cardParam(row);
        border-radius: 30px;
        margin-top: 80px;

        .firstCardContent {
            display: flex;
            flex-direction: column;
            padding: 50px;
            width: 50%;

            // justify-content: space-between;
            h2 {
                margin-bottom: 8px;
                @include textParam($secondary-color, $alexBrush, 50px, 66px, regular);
                text-align: left;
            }

            h3 {
                @include textParam($primary-color, $lato, 32px, 40px, bold);
                margin-bottom: 16px;
            }

            p {
                margin: 0;
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 26px, regular);
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                margin-bottom: 20px;
                width: 38%;

                &:hover {
                    background-color: $button-hover-color;
                }
            }

            .newSpaces {
                display: grid;
                grid-template-columns: repeat(3, auto);
                width: 100%;
                gap: 16px;
                margin-top: 24px;

                .newSpace {
                    // height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(48, 87, 129, 0.05);
                    width: 100%;
                    padding: 24px 16px;
                    border-radius: 8px;

                    .spaceIcon {
                        color: black;
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        // height: 100px;
                        h3 {
                            @include textParam(white, lato, 12px, 28px, 900);
                            margin: 0;
                            color: black;
                        }

                        p {
                            @include textParam(white, lato, 12px, 16px, 400);
                            color: black;
                            // margin-top: 4px;
                            margin: 0;
                        }
                    }

                }
            }
        }

        img {
            width: 50%;
            border-radius: 0 30px 30px 0;
        }
    }
}

@media only screen and (max-width:1024px) {
    .wosamLounge {
        header {
            h3 {
                @include textParam(white, lato, 16px, 36px, bold);
            }

            h1 {
                @include textParam(white, lato, 55px, 70px, bold);
                width: 65%;
            }

            p {
                @include textParam(white, lato, 16px, 28px, medium);
                width: 75%;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 23px, 28px, 700);
                padding: 16px 40px;
                border-radius: 50px;
                margin-bottom: 20px;
            }
        }

        p {
            margin: 40px 0;
            @include textParam($primary-color, lato, 16px, 21px, bold);
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 50px, 36px, bold);
        }

        .firstCard {
            .firstCardContent {
                padding: 30px;
                width: 50%;

                h2 {
                    @include textParam($secondary-color, $lato, 40px, 60px, regular);
                }

                h3 {
                    @include textParam($primary-color, $lato, 28px, 36px, bold);
                    margin-bottom: 15px;
                }

                p {
                    @include textParam($primary-color, $lato, 16px, 24px, regular);
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 28px, 32px, bold);
                    padding: 16px 40px;
                    border-radius: 50px;
                    margin-bottom: 20px;
                    width: 240px;
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;
                        border-radius: 8px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 50%;
                border-radius: 0 30px 30px 0;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .wosamLounge {
        header {
            padding: 40px 24px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
                @include textParam(white, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 36px, 45px, 700);
                width: 80%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 16px, 20px, 700);
                border-radius: 50px;
                margin-bottom: 20px;
                padding: 10px 20px;
            }
        }

        p {
            margin: 24px 0 16px;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            @include textParam($primary-color, lato, 34px, 40px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 24px 40px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 32px, 40px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 20px, 28px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 14px, 21px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 20px, 24px, bold);
                    width: 150px;
                    padding: 10px 16px;
                    margin-bottom: 16px;
                    border-radius: 25px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

@media only screen and (max-width:520px) {
    .wosamLounge {
        header {
            padding: 40px 20px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            h3 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 100%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                padding: 4px 16px;
                font-size: 10px;

            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }


        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 24px, 32px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 20px 20px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 20px, 32px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 16px, 24px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 12px, 20px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    width: 100px;
                    padding: 4px 16px;
                    margin-bottom: 16px;
                    font-size: 10px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 14px;
                    margin-top: 24px;

                    .newSpace {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(48, 87, 129, 0.05);
                        padding: 20px;

                        .spaceIcon {
                            color: black;
                            width: 30px;
                            height: 30px;
                            margin-right: 7px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

.wosamConcertSpace {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/concert space.png');
        padding: 80px 0;
        color: white;
        text-align: center;
        background-size: cover;
        background-position-x: center;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, lato, 18px, 40px, bold);
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 53%;
        }

        p {
            @include textParam(white, lato, 18px, 32px, 400);
            width: 70%;
            margin-bottom: 24px
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-bottom: 20px;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        // margin-top: 80px;
        @include textParam($primary-color, lato, 60px, 40px, bold);
        text-align: center;
        margin: 0
    }

    .firstCard {
        @include cardParam(row);
        border-radius: 30px;
        margin-top: 80px;

        .firstCardContent {
            display: flex;
            flex-direction: column;
            padding: 50px;
            width: 50%;

            // justify-content: space-between;
            h2 {
                margin-bottom: 8px;
                @include textParam($secondary-color, $alexBrush, 50px, 66px, regular);
                text-align: left;
            }

            h3 {
                @include textParam($primary-color, $lato, 32px, 40px, bold);
                margin-bottom: 16px;
            }

            p {
                margin: 0;
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 26px, regular);
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                margin-bottom: 20px;
                width: 38%;

                &:hover {
                    background-color: $button-hover-color;
                }
            }

            .newSpaces {
                display: grid;
                grid-template-columns: repeat(3, auto);
                width: 100%;
                gap: 16px;
                margin-top: 24px;

                .newSpace {
                    // height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(48, 87, 129, 0.05);
                    width: 100%;
                    padding: 24px 16px;
                    border-radius: 8px;

                    .spaceIcon {
                        color: black;
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        // height: 100px;
                        h3 {
                            @include textParam(white, lato, 12px, 28px, 900);
                            margin: 0;
                            color: black;
                        }

                        p {
                            @include textParam(white, lato, 12px, 16px, 400);
                            color: black;
                            // margin-top: 4px;
                            margin: 0;
                        }
                    }

                }
            }
        }

        img {
            width: 50%;
            border-radius: 0 30px 30px 0;
        }
    }
}

@media only screen and (max-width:1024px) {
    .wosamConcertSpace {
        header {
            h3 {
                @include textParam(white, lato, 16px, 36px, bold);
            }

            h1 {
                @include textParam(white, lato, 55px, 70px, bold);
                width: 65%;
            }

            p {
                @include textParam(white, lato, 16px, 28px, medium);
                width: 75%;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 23px, 28px, 700);
                padding: 16px 40px;
                border-radius: 50px;
                margin-bottom: 20px;
            }
        }

        p {
            margin: 40px 0;
            @include textParam($primary-color, lato, 16px, 21px, bold);
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 50px, 36px, bold);
        }

        .firstCard {
            .firstCardContent {
                padding: 30px;
                width: 50%;

                h2 {
                    @include textParam($secondary-color, $lato, 40px, 60px, regular);
                }

                h3 {
                    @include textParam($primary-color, $lato, 28px, 36px, bold);
                    margin-bottom: 15px;
                }

                p {
                    @include textParam($primary-color, $lato, 16px, 24px, regular);
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 28px, 32px, bold);
                    padding: 16px 40px;
                    border-radius: 50px;
                    margin-bottom: 20px;
                    width: 240px;
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;
                        border-radius: 8px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 50%;
                border-radius: 0 30px 30px 0;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .wosamConcertSpace {
        header {
            padding: 40px 24px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
                @include textParam(white, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 36px, 45px, 700);
                width: 80%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 16px, 20px, 700);
                border-radius: 50px;
                margin-bottom: 20px;
                padding: 10px 20px;
            }
        }

        p {
            margin: 24px 0 16px;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            @include textParam($primary-color, lato, 34px, 40px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 24px 40px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 32px, 40px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 20px, 28px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 14px, 21px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 20px, 24px, bold);
                    width: 150px;
                    padding: 10px 16px;
                    margin-bottom: 16px;
                    border-radius: 25px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

@media only screen and (max-width:520px) {
    .wosamConcertSpace {
        header {
            padding: 40px 20px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            h3 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 100%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                padding: 4px 16px;
                font-size: 10px;

            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }


        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 24px, 32px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 20px 20px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 20px, 32px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 16px, 24px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 12px, 20px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    width: 100px;
                    padding: 4px 16px;
                    font-size: 10px;
                    margin-bottom: 16px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 14px;
                    margin-top: 24px;

                    .newSpace {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(48, 87, 129, 0.05);
                        padding: 20px;

                        .spaceIcon {
                            color: black;
                            width: 30px;
                            height: 30px;
                            margin-right: 7px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}



.wosamConferenceHall {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/Concert Area In Ago iwoye ogun state.png');
        padding: 80px 0;
        color: white;
        text-align: center;
        background-size: cover;
        background-position-x: center;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, lato, 18px, 40px, bold);
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 53%;
        }

        p {
            @include textParam(white, lato, 18px, 32px, 400);
            width: 70%;
            margin-bottom: 24px
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-bottom: 20px;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        // margin-top: 80px;
        @include textParam($primary-color, lato, 60px, 40px, bold);
        text-align: center;
        margin: 0
    }

    .firstCard {
        @include cardParam(row);
        border-radius: 30px;
        margin-top: 80px;

        .firstCardContent {
            display: flex;
            flex-direction: column;
            padding: 50px;
            width: 50%;

            // justify-content: space-between;
            h2 {
                margin-bottom: 8px;
                @include textParam($secondary-color, $alexBrush, 50px, 66px, regular);
                text-align: left;
            }

            h3 {
                @include textParam($primary-color, $lato, 32px, 40px, bold);
                margin-bottom: 16px;
            }

            p {
                margin: 0;
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 26px, regular);
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                margin-bottom: 20px;
                width: 38%;

                &:hover {
                    background-color: $button-hover-color;
                }
            }

            .newSpaces {
                display: grid;
                grid-template-columns: repeat(3, auto);
                width: 100%;
                gap: 16px;
                margin-top: 24px;

                .newSpace {
                    // height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(48, 87, 129, 0.05);
                    width: 100%;
                    padding: 24px 16px;
                    border-radius: 8px;

                    .spaceIcon {
                        color: black;
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        // height: 100px;
                        h3 {
                            @include textParam(white, lato, 12px, 28px, 900);
                            margin: 0;
                            color: black;
                        }

                        p {
                            @include textParam(white, lato, 12px, 16px, 400);
                            color: black;
                            // margin-top: 4px;
                            margin: 0;
                        }
                    }

                }
            }
        }

        img {
            width: 50%;
            border-radius: 0 30px 30px 0;
        }
    }
}

@media only screen and (max-width:1024px) {
    .wosamConferenceHall {
        header {
            h3 {
                @include textParam(white, lato, 16px, 36px, bold);
            }

            h1 {
                @include textParam(white, lato, 55px, 70px, bold);
                width: 65%;
            }

            p {
                @include textParam(white, lato, 16px, 28px, medium);
                width: 75%;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 23px, 28px, 700);
                padding: 16px 40px;
                border-radius: 50px;
                margin-bottom: 20px;
            }
        }

        p {
            margin: 40px 0;
            @include textParam($primary-color, lato, 16px, 21px, bold);
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 50px, 36px, bold);
        }

        .firstCard {
            .firstCardContent {
                padding: 30px;
                width: 50%;

                h2 {
                    @include textParam($secondary-color, $lato, 40px, 60px, regular);
                }

                h3 {
                    @include textParam($primary-color, $lato, 28px, 36px, bold);
                    margin-bottom: 15px;
                }

                p {
                    @include textParam($primary-color, $lato, 16px, 24px, regular);
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 28px, 32px, bold);
                    padding: 16px 40px;
                    border-radius: 50px;
                    margin-bottom: 20px;
                    width: 240px;
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;
                        border-radius: 8px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 50%;
                border-radius: 0 30px 30px 0;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .wosamConferenceHall {
        header {
            padding: 40px 24px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
                @include textParam(white, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 36px, 45px, 700);
                width: 80%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 16px, 20px, 700);
                border-radius: 50px;
                margin-bottom: 20px;
                padding: 10px 20px;
            }
        }

        p {
            margin: 24px 0 16px;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            @include textParam($primary-color, lato, 34px, 40px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 24px 40px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 32px, 40px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 20px, 28px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 14px, 21px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 20px, 24px, bold);
                    width: 150px;
                    padding: 10px 16px;
                    margin-bottom: 16px;
                    border-radius: 25px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

@media only screen and (max-width:520px) {
    .wosamConferenceHall {
        header {
            padding: 40px 20px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            h3 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 100%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                // @include buttonParam($primary-color, $secondary-color);
                padding: 4px 16px;
                font-size: 10px;
            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }


        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 24px, 32px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 20px 20px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 20px, 32px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 16px, 24px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 12px, 20px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    width: 100px;
                    padding: 4px 16px;
                    margin-bottom: 16px;
                    font-size: 10px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 14px;
                    margin-top: 24px;

                    .newSpace {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(48, 87, 129, 0.05);
                        padding: 20px;

                        .spaceIcon {
                            color: black;
                            width: 30px;
                            height: 30px;
                            margin-right: 7px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

.wosamEventHall {
    display: flex;
    flex-direction: column;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/wosamEventHall.jpg');
        padding: 80px 0;
        color: white;
        text-align: center;
        background-size: cover;
        background-position-x: center;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, lato, 36px, 40px, bold);
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 53%;
        }

        p {
            @include textParam(white, lato, 18px, 32px, 400);
            width: 70%;
            margin-bottom: 24px
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-bottom: 20px;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        // margin-top: 80px;
        @include textParam($primary-color, lato, 60px, 40px, bold);
        text-align: center;
        margin: 0
    }

    .firstCard {
        @include cardParam(row);
        border-radius: 30px;
        margin-top: 80px;

        .firstCardContent {
            display: flex;
            flex-direction: column;
            padding: 50px;
            width: 50%;

            // justify-content: space-between;
            h2 {
                margin-bottom: 8px;
                @include textParam($secondary-color, $alexBrush, 50px, 66px, regular);
                text-align: left;
            }

            h3 {
                @include textParam($primary-color, $lato, 32px, 40px, bold);
                margin-bottom: 16px;
            }

            p {
                margin: 0;
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 26px, regular);
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                margin-bottom: 20px;
                width: 38%;

                &:hover {
                    background-color: $button-hover-color;
                }
            }

            .newSpaces {
                display: grid;
                grid-template-columns: repeat(3, auto);
                width: 100%;
                gap: 16px;
                margin-top: 24px;

                .newSpace {
                    // height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(48, 87, 129, 0.05);
                    width: 100%;
                    padding: 24px 16px;
                    border-radius: 8px;

                    .spaceIcon {
                        color: black;
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        // height: 100px;
                        h3 {
                            @include textParam(white, lato, 12px, 28px, 900);
                            margin: 0;
                            color: black;
                        }

                        p {
                            @include textParam(white, lato, 12px, 16px, 400);
                            color: black;
                            // margin-top: 4px;
                            margin: 0;
                        }
                    }

                }
            }
        }

        img {
            width: 50%;
            border-radius: 0 30px 30px 0;
        }
    }
}

@media only screen and (max-width:1024px) {
    .wosamEventHall {
        header {
            h3 {
                @include textParam(white, lato, 16px, 36px, bold);
            }

            h1 {
                @include textParam(white, lato, 55px, 70px, bold);
                width: 65%;
            }

            p {
                @include textParam(white, lato, 16px, 28px, medium);
                width: 75%;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 23px, 28px, 700);
                padding: 16px 40px;
                border-radius: 50px;
                margin-bottom: 20px;
            }
        }

        p {
            margin: 40px 0;
            @include textParam($primary-color, lato, 16px, 21px, bold);
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 50px, 36px, bold);
        }

        .firstCard {
            .firstCardContent {
                padding: 30px;
                width: 50%;

                h2 {
                    @include textParam($secondary-color, $lato, 40px, 60px, regular);
                }

                h3 {
                    @include textParam($primary-color, $lato, 28px, 36px, bold);
                    margin-bottom: 15px;
                }

                p {
                    @include textParam($primary-color, $lato, 16px, 24px, regular);
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 28px, 32px, bold);
                    padding: 16px 40px;
                    border-radius: 50px;
                    margin-bottom: 20px;
                    width: 240px;
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;
                        border-radius: 8px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 50%;
                border-radius: 0 30px 30px 0;
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .wosamEventHall {
        header {
            padding: 40px 24px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
                @include textParam(white, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 36px, 45px, 700);
                width: 80%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                @include textParam($primary-color, lato, 16px, 20px, 700);
                border-radius: 50px;
                margin-bottom: 20px;
                padding: 10px 20px;
            }
        }

        p {
            margin: 24px 0 16px;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            @include textParam($primary-color, lato, 34px, 40px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 24px 40px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 32px, 40px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 20px, 28px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 14px, 21px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    @include textParam($primary-color, $lato, 20px, 24px, bold);
                    width: 150px;
                    padding: 10px 16px;
                    margin-bottom: 16px;
                    border-radius: 25px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(172, 169, 169);
                        // width: 100%;
                        padding: 24px 32px;

                        .spaceIcon {
                            color: black;
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}

@media only screen and (max-width:520px) {
    .wosamEventHall {
        header {
            padding: 40px 20px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            h3 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 100%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                // @include buttonParam($primary-color, $secondary-color);
                padding: 4px 16px;
                font-size: 10px;
            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }


        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 24px, 32px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            margin: 0;
            margin: 20px 20px;
            @include cardParam(column);
            border-radius: 30px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 20px, 32px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 16px, 24px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 12px, 20px, regular);
                    text-align: left;
                }

                button {
                    // @include buttonParam($primary-color, $secondary-color);
                    // display: inline;
                    width: 100px;
                    // height: 32px;
                    padding: 4px 16px;
                    font-size: 10px;
                    margin-bottom: 16px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 14px;
                    margin-top: 24px;

                    .newSpace {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(48, 87, 129, 0.05);
                        padding: 20px;

                        .spaceIcon {
                            color: black;
                            width: 30px;
                            height: 30px;
                            margin-right: 7px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}


.wosamNightClub {
    header {
        display: flex;
        flex-direction: column;
        // align-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/Friday Party in wosam.png');
        padding: 100px 80px;
        color: white;
        text-align: left;
        background-size: cover;
        margin-top: 100px;

        h3 {
            @include textParam($secondary-color, lato, 18px, 40px, bold);
            text-align: left;
            margin-bottom: 24px;
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 70%;
            text-align: left;
        }

        p {
            @include textParam(white, lato, 18px, 32px, 400);
            width: 70%;
            margin-bottom: 24px;
            text-align: left;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            width: 15%;
            // display: inline-block;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        // margin-top: 80px;
        @include textParam($primary-color, lato, 60px, 40px, bold);
        text-align: center;
    }

    .firstCard {
        margin-top: 0;
        margin-top: 60px;
        @include cardParam(row);
        border-radius: 30px;

        .firstCardContent {
            display: flex;
            flex-direction: column;
            padding: 50px;
            width: 50%;

            // justify-content: space-between;
            h2 {
                margin-bottom: 8px;
                @include textParam($secondary-color, $lato, 50px, 66px, regular);
                text-align: left;
            }

            h3 {
                @include textParam($primary-color, $lato, 32px, 40px, bold);
                margin-bottom: 16px;
            }

            p {
                margin-top: 0;
                margin-bottom: 30px;
                @include textParam($primary-color, $lato, 18px, 26px, regular);
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                width: 38%;
                margin-bottom: 20px;

                &:hover {
                    background-color: $button-hover-color;
                }
            }

            .newSpaces {
                display: grid;
                grid-template-columns: repeat(3, auto);
                width: 100%;
                gap: 16px;
                margin-top: 24px;

                .newSpace {
                    // height: 85px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgb(48, 87, 129, 0.05);
                    width: 100%;
                    padding: 24px 16px;
                    border-radius: 8px;

                    .spaceIcon {
                        color: black;
                        width: 32px;
                        height: 32px;
                        margin-right: 8px;
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        // height: 100px;
                        h3 {
                            @include textParam(white, lato, 12px, 16px, bold);
                            margin: 0;
                            color: black;
                        }

                        p {
                            @include textParam(white, lato, 12px, 16px, 400);
                            color: black;
                            // margin-top: 4px;
                            margin: 0;
                        }
                    }

                }
            }
        }

        img {
            width: 50%;
            border-radius: 0 30px 30px 0;
        }
    }

}

@media only screen and (max-width:600px) {
    .wosamNightClub {
        header {
            padding: 40px 20px;
            background-size: cover;
            text-align: left;
            margin-top: 80px;

            h3 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
                margin: 0;
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 80%;
                margin: 0;
                margin-bottom: 16px;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                padding: 8px 16px;
                width: 24%;
                font-size: 10px;
            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 24px, 32px, 700);
            text-align: center;
            margin: 0;
        }

        .firstCard {
            @include cardParam(column);
            border-radius: 30px;
            margin: 0;
            margin: 24px 40px;

            .firstCardContent {
                display: flex;
                flex-direction: column;
                padding: 24px 16px;
                width: 100%;

                // justify-content: space-between;
                h2 {
                    margin-bottom: 8px;
                    @include textParam($secondary-color, $lato, 20px, 32px, regular);
                    text-align: left;
                }

                h3 {
                    @include textParam($primary-color, $lato, 16px, 24px, bold);
                    margin-bottom: 16px;
                }

                p {
                    margin: 0;
                    margin-bottom: 16px;
                    @include textParam($primary-color, $lato, 12px, 20px, regular);
                    text-align: left;
                }

                button {
                    @include buttonParam($primary-color, $secondary-color);
                    width: 100px;
                    // height: 40px;
                    padding: 8px 16px;
                    margin-bottom: 16px;
                    font-size: 10px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }

                .newSpaces {
                    display: grid;
                    grid-template-columns: repeat(2, auto);
                    width: 100%;
                    gap: 16px;
                    margin-top: 24px;

                    .newSpace {
                        // height: 85px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgb(48, 87, 129, 0.05);
                        width: 100%;
                        padding: 20px;
                        border-radius: 8px;

                        .spaceIcon {
                            color: black;
                            width: 30px;
                            height: 30px;
                            margin-right: 7px;
                        }

                        div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            // height: 100px;
                            h3 {
                                @include textParam(white, lato, 12px, 16px, bold);
                                margin: 0;
                                color: black;
                            }

                            p {
                                @include textParam(white, lato, 12px, 16px, 400);
                                color: black;
                                // margin-top: 4px;
                                margin: 0;
                            }
                        }

                    }
                }
            }

            img {
                width: 100%;
                border-radius: 0 0 30px 0;
                height: 400px;
            }
        }

    }

}




.wosamonthemove {

    header {
        display: flex;
        align-items: center;
        column-gap: 50px;
        padding: 40px 0;
        padding-right: 80px;
        background-image: linear-gradient(rgba(0, 43, 90, 0.8), rgba(0, 43, 90, 0.8)), url('../assets/images/wosamonthemoveBg.png');
        margin-top: 100px;

        .truck {
            h3 {
                @include textParam(white, lato, 18px, 40px, bold);
                margin-left: 80px;
                margin-bottom: 16px;
            }
        }

        form {
            background-color: white;
            padding: 40px;
            border-radius: 16px;

            h2 {
                @include textParam($primary-color, lato, 28px, 40px, 900);
                margin-bottom: 30px;

                span {
                    @include textParam($primary-color, lato, 18px, 24px, 900);
                }
            }

            .formInput {
                display: grid;
                grid-template-columns: repeat(2, auto);
                width: 100%;
                column-gap: 8px;
                margin-bottom: 8px;

                .location {
                    border-radius: 8px;
                    width: 100%;
                    height: 50px;
                    position: relative;
                    background-color: rgb(242, 242, 242);
                    // border: 0;

                    .locationInput {
                        // border: 1px solid $primary-color;
                        background-color: rgb(242, 242, 242);
                        border-radius: 8px;
                        // border: 0;
                        width: 100%;
                        height: 50px;
                        padding: 12px;
                        padding-left: 40px;
                        outline: 0;
                    }

                    .locationIcon {
                        position: absolute;
                        left: 16px;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                }

                input {
                    height: 50px;
                    background-color: rgb(242, 242, 242);
                    border-radius: 8px;
                    // border: 0;
                    width: 100%;
                    padding: 12px;
                    // padding-left: 32px;
                    outline: 0;

                    &:focus {
                        border: 1px solid $primary-color;
                    }
                }
            }

            input {
                // border: 1px solid $primary-color;
                background-color: rgb(242, 242, 242);
                height: 50px;
                border-radius: 8px;
                border: 0;
                width: 100%;
                padding: 12px;
                // padding-left: 32px;
                outline: 0;
                margin-bottom: 10px;

                &:focus {
                    border: 1px solid $primary-color;
                }
            }

            button {
                @include buttonParam(white, $primary-color);
                width: 100%;
                margin-top: 24px;
            }
        }
    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    .photoBooth {
        height: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        column-gap: 50px;
        padding: 80px;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/photoBoothBg.png');

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            // margin: 0;
            // margin-bottom: 40px;
        }

        p {
            @include textParam(white, lato, 18px, 40px, 300);
            margin-bottom: 40px;
            width: 70%;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    .photoBooth2 {
        display: grid;
        grid-template-columns: repeat(3, auto);
        column-gap: 40px;
        row-gap: 50px;
        margin: 150px 80px;

        div {
            display: flex;
            flex-direction: column;
            text-align: center;
            background-color: rgb(241, 241, 241);
            border-radius: 8px;

            img {
                width: 100%;
                object-fit: cover;
            }

            h3 {
                @include textParam($primary-color, lato, 28px, 40px, bold);
                margin-top: 24px
            }

            p {
                @include textParam($primary-color, lato, 16px, 24px, 400);
                margin: 24px 32px;
                margin-top: 16px;
            }
        }
    }

    .requestQuote2 {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/galleryRequestQuoteBg.jpg');
        width: 100%;
        display: grid;
        place-items: center;
        padding: 100px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 30px;
            background-color: white;
            border-radius: 16px;
            width: 541px;

            h3 {
                @include textParam($primary-color, lato, 33px, 44px, bold);
                margin-bottom: 24px;
            }

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;

                div {
                    display: flex;
                    column-gap: 16px;
                }

                input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    outline: 0;
                    font-size: 16px;

                    &::placeholder {
                        color: white;
                        font-size: 16px;
                    }

                }

                textarea {
                    height: 108px;
                    display: block;
                    width: 100%;
                    // height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    padding-top: 16px;
                    outline: 0;
                    font-size: 16px;
                    overflow: hidden;

                    &::placeholder {
                        @include textParam(white, lato, 16px, 19px, bold);
                    }
                }

                button {
                    width: 100%;
                    padding: 16px 0;
                    background-color: $secondary-color;
                    color: $primary-color;
                    border: 0;
                    border-radius: 50px;
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }
            }

            .contacts {
                display: flex;
                justify-content: space-between;
                width: 100%;

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .wosamonthemove {
        header {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 40px 24px;
            margin-top: 80px;

            .truck {
                width: 100%;
                // margin-top: 40px;

                h3 {
                    @include textParam($secondary-color, lato, 14px, 17px, semibold);
                    margin: 0;
                    margin-bottom: 24px;
                }

                img {
                    // object-fit: cover;
                    width: 100%
                }
            }

            form {
                padding: 24px 16px;
                margin-top: 24px;

                h2 {
                    @include textParam($primary-color, lato, 20px, 30px, 700);
                    margin-bottom: 0;
                    margin-bottom: 16px;
                    text-align: center;

                    span {
                        @include textParam($primary-color, lato, 16px, 30px, 700);

                    }
                }
            }
        }

        p {
            margin: 24px;
        }

        .photoBooth {
            padding: 40px 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @include textParam($primary-color, lato, 12px, 16px, bold);
            align-items: flex-start;
            align-items: center;
            background-size: cover;
            height: auto;

            h1 {
                @include textParam(white, lato, 28px, 36px, bold);
                margin-bottom: 16px
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 80%;
                text-align: center;
                margin: 0;
                margin-bottom: 24px;
            }

            button {
                padding: 8px 16px;
                font-size: 12px;
            }
        }

        .photoBooth2 {
            grid-template-columns: repeat(1, auto);
            margin: 0;
            margin: 40px 24px;
            gap: 24px;

            div {
                width: 100%;

                img {
                    height: 200px;
                    object-fit: cover;
                }

                h3 {
                    @include textParam($primary-color, lato, 18px, 24px, 700);
                    text-align: left;
                    margin: 0;
                    margin: 16px 16px 0;
                }

                p {
                    @include textParam($primary-color, lato, 12px, 20px, 400);
                    text-align: left;
                    // width: 100%;
                    margin: 0;
                    margin: 8px 16px 24px;
                }
            }
        }

        .requestQuote2 {
            padding: 40px 24px;

            .formContainer {
                width: 100%;
                padding: 24px 16px;

                h3 {
                    @include textParam($primary-color, lato, 24px, 30px, 700);

                }

                .contacts {
                    display: flex;
                    justify-content: space-between;

                    span {
                        @include textParam($primary-color, lato, 9px, 20px, 700);

                        .icon {
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
}




.cinemaPage {

    header {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/cinemaBg.jpg');
        padding: 80px;
        margin-top: 100px;

        h4 {
            @include textParam($secondary-color, lato, 18px, 40px, bold);
        }

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 60%;
        }

        p {
            @include textParam(white, lato, 18px, 30px, 400);
            width: 60%;
            text-align: left;
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            margin-top: 40px;

            &:hover {
                background-color: $button-hover-color;
            }
        }


    }

    p {
        margin: 40px 0;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        text-align: center;
    }

    h2 {
        margin: 8px 0;
        text-align: center;
        @include textParam($primary-color, lato, 60px, 40px, bold);

    }

    .ourMovies {
        display: grid;
        grid-template-columns: repeat(2, auto);
        margin: 40px 80px;
        gap: 40px;

        div {
            padding: 16px;
            display: flex;
            flex-direction: column;
            background-color: rgb(233, 233, 233);
            border-radius: 8px;

            img {
                width: 100%;
                object-fit: cover;
            }

            h5 {
                @include textParam($primary-color, lato, 26px, 40px, semibold);
                margin-top: 24px;
                margin-bottom: 8px;
            }

            p {
                @include textParam($primary-color, lato, 18px, 28px, 400);
                text-align: left;
                margin: 0;
            }

            .movieFooter {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                button {
                    @include buttonParam(white, $primary-color);
                    // width: 35%;
                }

                p {
                    @include textParam($primary-color, lato, 24px, 40px, semibold);
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .cinemaPage {
        header {
            padding: 40px 20px;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 80px;

            h4 {
                @include textParam($secondary-color, lato, 14px, 40px, bold);
            }

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                width: 100%;
                margin: 0;
                margin-bottom: 16px;
                text-align: left;
            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                width: 90%;
                margin: 0;
                margin-bottom: 24px;
                margin-bottom: 24px;
                text-align: left;
            }

            button {
                @include buttonParam($primary-color, $secondary-color);
                padding: 8px 16px;
                margin: 0;
                font-size: 12px;
            }
        }

        p {
            margin: 24px 0;
            @include textParam($primary-color, lato, 12px, 20px, bold);
            text-align: center;
        }

        h2 {
            // margin-top: 80px;
            @include textParam($primary-color, lato, 32px, 38px, 700);
            text-align: center;
            margin: 0;
        }

        .ourMovies {
            grid-template-columns: repeat(1, auto);
            margin: 0;
            margin: 24px 16px;
            gap: 32px;
            // width: 100%;

            div {
                // width: 100%;

                img {
                    // width: 100%;
                    height: 400px;
                    object-fit: cover;
                }

                h5 {
                    @include textParam($primary-color, lato, 18px, 24px, 700);
                    text-align: left;
                    margin: 0;
                    padding: 0;
                    margin: 16px 0px 0
                }

                p {
                    // width: 100%;
                    @include textParam($primary-color, lato, 12px, 20px, 400);
                    text-align: left;
                    // width: 100%;
                    padding: 0;
                    margin: 0;
                    margin: 8px 0px 24px;
                }

                .movieFooter {
                    margin: 0;
                    padding: 0;
                    margin-bottom: 24px;

                    button {
                        padding: 8px 16px;
                        margin: 0;
                        font-size: 12px;
                        width: 120px;

                    }

                    p {
                        @include textParam($primary-color, lato, 18px, 20px, 700);
                        margin: 0;
                        // width: 100%;
                    }
                }
            }
        }
    }
}




.latestNewsPage {
    header {
        text-align: center;
        margin: 40px;
        margin-top: 160px;

        span {
            @include dashParam();
            margin: auto;
            display: flex;
            // text-align: center

        }

        h2 {
            @include textParam($primary-color, lato, 70px, 95px, bold);
        }
    }

    .buju2 {
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/buju2.png');
        background-size: cover;
        height: 390px;
        display: grid;
        place-items: center;

        h3 {
            @include textParam(white, lato, 24px, 40px, medium);
        }
    }

    p {
        @include textParam($primary-color, lato, 16px, 21px, bold);
        margin: 24px;
        text-align: center;
    }

    .latestDiv {
        @include cardParam(row);
        display: flex;
        margin: 0px 80px;

        img {
            width: 50%;
        }

        section {
            padding: 40px;

            h3 {
                @include textParam($primary-color, lato, 36px, 48px, medium);
            }

            p {
                @include textParam($primary-color, lato, 18px, 28px, 400);
                text-align: left;
                margin-left: 0
            }

            button {
                @include buttonParam($primary-color, white);
                border: 2px solid $primary-color;

                &:hover {
                    background-color: $primary-color;
                    color: white;
                }
            }
        }
    }

    .news {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 50px;
        margin: 100px 80px;

        div {
            display: flex;
            flex-direction: column;
            padding: 16px;
            border: 1px solid $secondary-color;
            border-radius: 8px;

            img {
                width: 100%;
                margin-bottom: 16px
            }

            h4 {
                @include textParam($primary-color, lato, 24px, 35px, bold);
            }

            p {
                @include textParam($primary-color, lato, 18px, 28px, 300);
                text-align: left;
                margin-left: 0;
            }

            button {
                @include buttonParam($primary-color, white);
                border: 2px solid $primary-color;
                width: 45%;

                &:hover {
                    background-color: $primary-color;
                    color: white;
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .latestNewsPage {
        header {
            margin-top: 80px;
            padding-top: 24px;

            h2 {
                @include textParam($primary-color, lato, 32px, 39px, 800);
                margin-top: 16px;
            }
        }

        .buju2 {
            padding: 120px 0;
            background-size: cover;
            margin-top: 40px;

            h3 {
                @include textParam(white, lato, 32px, 35px, regular);
                width: 80%;
                // margin-bottom: 20px;
                text-align: center;
            }
        }

        p {
            @include textParam($primary-color, lato, 16px, 21px, bold);
            margin: 24px;
            text-align: center;
        }

        .latestDiv {
            display: flex;
            flex-direction: column;
            margin: 0;
            margin: 40px 24px;

            img {
                width: 100%;
            }

            section {
                padding: 0;
                padding: 24px 16px;

                h3 {
                    @include textParam($primary-color, lato, 18px, 24px, 700);
                    margin-bottom: 8px;
                }

                p {
                    @include textParam($primary-color, lato, 12px, 20px, 400);
                    width: 100%;
                    margin: 0;
                    margin-bottom: 24px;
                }

                button {
                    padding: 8px 16px;
                    margin-bottom: 24px;
                    font-size: 10px;
                }
            }
        }

        .news {
            grid-template-columns: repeat(1, auto);
            margin: 0;
            gap: 16px;
            margin: 40px 24px;

            div {
                padding: 8px;

                img {
                    height: 300px
                }

                h4 {
                    @include textParam($primary-color, lato, 16px, 24px, 700);
                    width: 100%;
                    margin: 0;
                    margin-bottom: 4px;
                }

                p {
                    @include textParam($primary-color, lato, 12px, 20px, 400);
                    margin: 0;
                    width: 100%;
                    margin-bottom: 8px;
                }

                button {
                    padding: 8px 16px;
                    width: 100px;
                    margin-bottom: 16px;
                    font-size: 10px;
                    // height: auto;
                }
            }
        }
    }
}




.careerPage {
    header {
        height: 85vh;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/Jobs in Ago iwoye Ogun state.png');
        background-size: cover;
        background-position: center center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 100px;

        h1 {
            @include textParam(white, lato, 70px, 95px, bold);
            width: 50%;
            // margin-bottom: 16px
        }

        p {
            @include textParam(white, lato, 18px, 40px, 400);
            width: 30%;
            // margin-bottom: 24px
        }

        button {
            @include buttonParam($primary-color, $secondary-color);
            width: 15%;

            &:hover {
                background-color: $button-hover-color;
            }
        }
    }

    p {
        text-align: center;
        @include textParam($primary-color, lato, 16px, 21px, bold);
        margin: 40px 0;
    }

    section {
        width: 100%;

        h2 {
            text-align: center;
            @include textParam($primary-color, lato, 40px, 54px, semibold);
            margin-bottom: 24px;
        }

        p {
            @include textParam($primary-color, lato, 18px, 40px, 400);
            margin-top: 0;
            width: 35%;
            margin: auto;
            text-align: center;
        }

        .careerDiv {
            display: grid;
            grid-template-columns: repeat(3, auto);
            margin: 60px 80px;
            column-gap: 32px;

            .one {
                padding: 24px 16px;
                width: 100%;
                background-color: #f2f2f2;
                border-radius: 8px;

                h4 {
                    text-align: right;
                    @include textParam($primary-color, lato, 40px, 53px, semibold);
                    margin-bottom: 16px;
                }

                h3 {
                    @include textParam($primary-color, lato, 35px, 48px, semibold);
                    margin-bottom: 16px;

                }

                p {
                    @include textParam($primary-color, lato, 18px, 30px, 300);
                    text-align: left;
                    margin: 0;
                    width: 100%;
                }
            }

            .two {
                padding: 24px 16px;
                width: 100%;
                background-color: #ffe3be;
                border-radius: 8px;

                h4 {
                    text-align: right;
                    @include textParam($primary-color, lato, 40px, 53px, semibold);
                    margin-bottom: 16px;
                }

                h3 {
                    @include textParam($primary-color, lato, 35px, 48px, semibold);
                    margin-bottom: 16px;

                }

                p {
                    @include textParam($primary-color, lato, 18px, 30px, 300);
                    text-align: left;
                    margin: 0;
                    width: 100%;
                }
            }

            .three {
                padding: 24px 16px;
                width: 100%;
                background-color: rgba(0, 43, 90);
                border-radius: 8px;

                h4 {
                    text-align: right;
                    @include textParam($primary-color, lato, 40px, 53px, semibold);
                    color: white;
                    margin-bottom: 16px;
                }

                h3 {
                    @include textParam($primary-color, lato, 35px, 48px, semibold);
                    color: white;
                    margin-bottom: 16px;

                }

                p {
                    @include textParam($primary-color, lato, 18px, 30px, 300);
                    color: white;
                    text-align: left;
                    margin: 0;
                    width: 100%;
                }
            }
        }

    }

    .jobDiv {
        margin: 80px 0;

        h2 {
            @include textParam($primary-color, lato, 40px, 54px, bold);
            text-align: center;
        }

        .jobs {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            margin: 40px 80px;

            .job {
                display: flex;
                padding: 24px;
                align-items: center;
                justify-content: space-between;
                border: 1px solid rgb(233, 233, 233);
                border-radius: 8px;

                div {
                    display: flex;
                    column-gap: 40px;
                    align-items: center;

                    h3 {
                        @include textParam($primary-color, lato, 36px, 48px, medium);

                        span {
                            @include textParam($primary-color, lato, 18px, 28px, 400);
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .careerForm {
        // margin-top: 180px;
        display: grid;
        place-items: center;
        background-image: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/Wosam Apartment In Ogun State Ago Iwoye.png');
        padding: 80px;
        background-size: cover;

        h2 {
            @include textParam(white, lato, 55px, 74px, bold);
            width: 70%;
            text-align: center
        }

        p {
            @include textParam(white, lato, 24px, 30px, medium);
            width: 70%;
            text-align: center;
            margin: 0;
            margin-top: 16px;
        }

        .formContainer {
            padding: 40px;
            width: 50%;
            display: flex;
            flex-direction: column;
            background-color: white;
            margin-top: 60px;
            border-radius: 16px;

            h3 {
                @include textParam($primary-color, Segoe UI, 30px, 32px, bold);
                text-align: center;
            }

            form {
                margin-bottom: 30px;
                width: 100%;

                input,
                select {
                    height: 50px;
                    font-size: 14px;
                    display: block;
                    width: 100%;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    margin: 16px 0;
                    padding: 16px;

                    &::placeholder {
                        font-size: 14px;
                        color: white;
                    }

                }

                textarea {
                    height: 108px;
                    font-size: 14px;
                    width: 100%;
                    background-color: $primary-color;
                    color: white;
                    padding: 16px;
                    border-radius: 8px;

                    &::placeholder {
                        @include textParam(white, lato, 14px, 19px, bold);
                        color: white;
                    }
                }

                button {
                    font-size: 16px;
                    @include buttonParam($primary-color, $secondary-color);
                    width: 100%;
                    margin-top: 32px;
                    margin-bottom: 16px;

                    &:hover {
                        background-color: $button-hover-color;
                    }
                }
            }

            .contacts {
                display: flex;
                justify-content: center;
                column-gap: 24px;

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .careerPage {
        header {
            padding: 40px 24px;
            background-size: cover;
            height: auto;
            align-items: flex-start;
            text-align: left;
            margin-top: 80px;

            h1 {
                @include textParam(white, lato, 32px, 38px, 700);
                margin: 0;
                width: 80%;
                margin-bottom: 8px;

            }

            p {
                @include textParam(white, lato, 12px, 20px, 400);
                margin: 0;
                width: 80%;
                text-align: left;
                margin-bottom: 24px;
            }

            button {
                padding: 4px 16px;
                font-size: 10px;
                width: 25%;

            }
        }

        p {
            margin: 0;
            margin: 24px;
            @include textParam($primary-color, lato, 12px, 20px, 700);
        }

        section {
            h2 {
                @include textParam($primary-color, lato, 24px, 32px, 700);
                margin: 0;
                margin-bottom: 16px;
            }

            p {
                @include textParam($primary-color, lato, 12px, 20px, 400);
                width: 80%;
            }

            .careerDiv {
                margin: 0;
                margin: 24px 24px;
                display: grid;
                grid-template-columns: repeat(1, auto);
                row-gap: 16px;

                .one,
                .two,
                .three {
                    padding: 40px 24px;

                    h4 {
                        @include textParam($primary-color, lato, 18px, 22px, 600);
                    }

                    h3 {
                        @include textParam($primary-color, lato, 24px, 30px, 700);
                    }

                    p {
                        @include textParam($primary-color, lato, 12px, 20px, 400);

                    }
                }

                .three {
                    h4 {
                        @include textParam(white, lato, 18px, 22px, 600);
                    }

                    h3 {
                        @include textParam(white, lato, 24px, 30px, 700);
                    }

                    p {
                        @include textParam(white, lato, 12px, 20px, 400);

                    }
                }
            }
        }

        .jobDiv {
            margin: 0;
            margin-top: 60px;

            h2 {
                @include textParam($primary-color, lato, 24px, 32px, 700);
            }

            .jobs {
                margin: 0;
                margin: 24px;
                row-gap: 16px;

                .job {
                    padding: 16px;

                    div {
                        column-gap: 24px;

                        img {
                            width: 50px;
                            height: 50px;
                        }

                        h3 {
                            @include textParam($primary-color, lato, 16px, 24px, 700);

                            span {
                                @include textParam($primary-color, lato, 12px, 30px, 400);
                            }
                        }
                    }

                    .jobIcon {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .careerForm {
            padding: 24px 16px;
            margin: 40px 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            h2 {
                width: 100%;
                text-align: center;
                margin: 0;
                margin: 24px 0 16px;
                @include textParam(white, lato, 18px, 30px, 700);
                padding: 0;
            }

            p {
                width: 100%;
                margin: 0;
                @include textParam(white, lato, 12px, 20px, 400);
                padding: 0;
                margin-bottom: 24px;

            }

            .formContainer {
                padding: 24px 16px;
                width: 100%;
                margin: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                h3 {
                    width: 100%;
                    margin: 0;
                    @include textParam($primary-color, lato, 18px, 30px, 700);
                    padding: 0;
                }

                form {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                }

                .contacts {
                    span {
                        font-size: 9px;
                        display: flex;
                        align-items: center;

                        .icon {
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
}






.requestQuote3 {
    display: flex;
    flex-direction: column;
    padding: 120px 80px;
    background: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/contactBackground.jpg');
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        margin-bottom: 40px;

        h3 {
            @include textParam(white, Segoe UI, 50px, 67px, bold);
            margin-bottom: 30px;
        }

        p {
            @include textParam(white, Segoe UI, 24px, 40px, medium);
            width: 100%;
            text-align: center;
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 30px;
        background-color: white;
        border-radius: 16px;
        width: 541px;
        // width: 100%;

        h3 {
            @include textParam($primary-color, Segoe UI, 33px, 44px, bold);
            margin-bottom: 24px;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 30px;

            input {
                display: block;
                width: 100%;
                height: 50px;
                margin-bottom: 16px;
                background-color: $primary-color;
                color: white;
                border: 0;
                border-radius: 8px;
                padding-left: 16px;
                outline: 0;
                font-size: 16px;

                &::placeholder {
                    color: white;
                    font-size: 16px;
                }

            }

            textarea {
                height: 108px;
                display: block;
                width: 100%;
                // height: 50px;
                margin-bottom: 16px;
                background-color: $primary-color;
                color: white;
                border: 0;
                border-radius: 8px;
                padding-left: 16px;
                padding-top: 16px;
                outline: 0;
                font-size: 16px;
                overflow: hidden;

                &::placeholder {
                    @include textParam(white, lato, 16px, 19px, bold);
                }
            }

            button {
                width: 100%;
                padding: 16px 0;
                background-color: $secondary-color;
                color: $primary-color;
                border: 0;
                border-radius: 50px;
                font-size: 18px;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: $button-hover-color;
                }
            }
        }

        .contacts {
            display: flex;
            justify-content: space-between;
            width: 100%;

            span {
                @include textParam($primary-color, lato, 14px, 17px, semibold);
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 8px;
                }
            }
        }
    }

}

@media only screen and (max-width:1200px) {
    .requestQuote3 {

        .content {
            width: 40%;
        }

        .formContainer {
            width: 450px;
        }
    }
}

@media only screen and (max-width:990px) {
    .requestQuote3 {
        padding: 80px 40px;

        .content {
            width: 35%;

            h3 {
                @include textParam(white, Segoe UI, 45px, 65px, bold);
                margin-top: -100px;
            }

            p {
                @include textParam(white, Segoe UI, 18px, 28px, medium);
            }
        }

        .formContainer {
            width: 400px;
        }
    }
}

@media only screen and (max-width:740px) {
    .requestQuote3 {
        display: flex;
        flex-direction: column;
        background: none;
        padding: 0 0;
        align-items: center;
        justify-content: center;
        margin-top: 80px;

        .content {
            display: flex;
            background: linear-gradient(rgba(0, 43, 90, 0.6), rgba(0, 43, 90, 0.6)), url('../assets/images/contactBackground.jpg'), no-repeat;
            background-size: cover;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            width: 100%;
            padding: 230px 100px 100px 100px;

            h3 {
                @include textParam(white, Segoe UI, 45px, 50px, bold);
                margin-bottom: 30px;
            }

            p {
                @include textParam(white, Segoe UI, 20px, 40px, medium);
                // width: 100%;
            }
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 50px;
            background-color: white;
            border-radius: 16px;
            width: 100%;

            h3 {
                @include textParam($primary-color, Segoe UI, 33px, 44px, bold);
                margin-bottom: 24px;
            }

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;

                input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    outline: 0;
                    font-size: 16px;

                    &::placeholder {
                        color: white;
                        font-size: 16px;
                    }

                }

                textarea {
                    height: 108px;
                    display: block;
                    width: 100%;
                    // height: 50px;
                    margin-bottom: 16px;
                    background-color: $primary-color;
                    color: white;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    padding-top: 16px;
                    outline: 0;
                    font-size: 16px;
                    overflow: hidden;

                    &::placeholder {
                        @include textParam(white, lato, 16px, 19px, bold);
                    }
                }

                button {
                    width: 100%;
                    padding: 16px 0;
                    background-color: $secondary-color;
                    color: $primary-color;
                    border: 0;
                    border-radius: 50px;
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;

                }
            }

            .contacts {
                display: flex;
                justify-content: space-between;
                width: 100%;

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width:600px) {
    .requestQuote3 {

        .content {
            padding: 230px 24px 100px 24px;

            h3 {
                @include textParam(white, Segoe UI, 40px, 30px, bold);
                margin-bottom: 30px;
            }

            p {
                @include textParam(white, Segoe UI, 18px, 35px, medium);
                width: 100%;
            }
        }

        .formContainer {
            padding: 40px;
            width: 100%;

            h3 {
                @include textParam($primary-color, Segoe UI, 30px, 32px, bold);
            }

            form {
                margin-bottom: 30px;

                input {
                    height: 50px;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }

                }

                textarea {
                    height: 108px;
                    font-size: 14px;

                    &::placeholder {
                        @include textParam(white, lato, 14px, 19px, bold);
                    }
                }

                button {
                    font-size: 16px;

                }
            }

            .contacts {

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width:440px) {
    .requestQuote3 {

        .content {
            padding: 230px 50px 100px 50px;

            h3 {
                @include textParam(white, Segoe UI, 36px, 30px, bold);
                margin-bottom: 30px;
            }

            p {
                @include textParam(white, Segoe UI, 16px, 35px, medium);
            }
        }

        .formContainer {
            padding: 40px 20px;
            width: 100%;

            h3 {
                @include textParam($primary-color, Segoe UI, 24px, 32px, bold);
            }

            form {
                margin-bottom: 30px;

                input {
                    height: 50px;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                    }

                }

                textarea {
                    height: 108px;
                    font-size: 14px;

                    &::placeholder {
                        @include textParam(white, lato, 14px, 19px, bold);
                    }
                }

                button {
                    font-size: 16px;

                }
            }

            .contacts {

                span {
                    @include textParam($primary-color, lato, 14px, 17px, semibold);

                    .icon {
                        margin-right: 8px;
                    }
                }
            }
        }

    }
}




.bookings {
    margin-top: 100px;
    display: flex;
    flex-direction: column;

    .upperContents {
        display: flex;
        flex-direction: row;
        // align-items: center;
        width: 100%;
        margin-top: 0px;
        column-gap: 40px;
        // padding: 0 80px;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 50px;
            background-image: url('../assets/images/threeBedroomBg.png');
            background-position-x: center;
            background-repeat: no-repeat;
            width: 50%;

            h1 {
                @include textParam($on-primary-color, $lato, 50px, 46px, 800);
                margin-bottom: 14px;
            }

            span {
                @include textParam($on-primary-color, $lato, 32px, 46px, bold);
            }

            p {
                @include textParam($on-primary-color, $lato, 18px, 44px, bold);
            }
        }

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 30px 30px 8px;
            margin: 16px 80px 40px 50px;
            background-color: white;
            border-radius: 10px;
            // height: 579px;
            width: 485px;
            // width: 50%;
            border: 0.2px solid #002B5A;

            h3 {
                @include textParam($primary-color, Segoe UI, 33px, 44px, bold);
                margin-bottom: 24px;
            }

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;

                label {
                    @include textParam($primary-color, $lato, 18px, 40px, bold);
                }

                input {
                    display: block;
                    width: 100%;
                    height: 50px;
                    margin-bottom: 16px;
                    background-color: #F2F2F2;
                    color: $primary-color;
                    font-weight: 700;
                    border: 0;
                    border-radius: 8px;
                    padding-left: 16px;
                    padding-right: 16px;
                    outline: 0;
                    font-size: 16px;

                    &::placeholder {
                        color: $primary-color;
                        font-size: 16px;
                    }

                }

                input[type="date"]:before {
                    content: attr(placeholder) !important;
                    margin-right: 0.5em;
                }

                input[type="date"]:focus:before {
                    content: '' !important;
                }

                span {
                    @include textParam($primary-color, Segoe UI, 22px, 46px, bold);
                    text-align: right;
                    margin-bottom: 8px;
                }

                button {
                    width: 100%;
                    padding: 16px 0;
                    background-color: $primary-color;
                    color: #fff;
                    border: 0;
                    border-radius: 50px;
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;

                    &:hover {
                        color: #305781;
                    }
                }
            }
        }
    }

    .lowerContents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 80px 0;

        span {
            @include textParam($primary-color, $lato, 18px, 40px, 800);
            margin-bottom: 18px;
        }

        h1 {
            @include textParam($primary-color, $lato, 40px, 40px, 800);
            margin-bottom: 16px;
        }

        p {
            @include textParam($primary-color, $lato, 24px, 40px, 500);
            margin-bottom: 36px;
        }

        .images {
            display: grid;
            grid-template-columns: repeat(5, auto);
            gap: 20px;
            margin: 30px 0 50px;

            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 155px;
                height: 145px;
                background-color: #F5F5F5;

                img {
                    width: 53px;
                    height: 42px;
                }

                span {
                    @include textParam($primary-color, $lato, 18px, 46px, 700);
                }
            }
        }

        h2 {
            @include textParam($primary-color, $lato, 24px, 46px, 700);
        }

        strong {
            @include textParam($primary-color, $lato, 32px, 46px, 800);
        }
    }
}




@media only screen and (max-width: 1200px) {
    .bookings {
        .content {
            padding-left: 20px;
            width: 65%;
        }

        .formContainer {
            margin: 40px 50px;

            label {
                @include textParam($primary-color, $lato, 14px, 30px, bold);
                margin-top: 5px;
            }
        }

        .lowerContents {
            padding: 40px 40px 0;
        }
    }
}

@media only screen and (max-width: 992px) {
    .bookings {
        .upperContents {
            flex-direction: column;

            .content {
                width: 100%;
                height: 450px;
            }

            .formContainer {
                margin: 40px;
            }
        }

        .lowerContents {
            padding: 40px;

            .images {
                grid-template-columns: repeat(4, auto);
                gap: 14px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .bookings {
        margin-top: 80px;

        .upperContents {
            .content {
                padding-left: 20px;

                h1 {
                    @include textParam($on-primary-color, $lato, 30px, 38px, 800);
                }

                span {
                    @include textParam($on-primary-color, $lato, 22px, 32px, bold);
                }

                p {
                    @include textParam($on-primary-color, $lato, 14px, 28px, bold);
                }
            }

            .formContainer {
                padding: 40px 20px;
                margin: 40px 20px;
                width: 90vw;
                height: 550px;

                h3 {
                    @include textParam($primary-color, Segoe UI, 24px, 32px, bold);
                }

                form {
                    input {
                        font-size: 14px;

                        &::placeholder {
                            font-size: 14px;
                        }

                    }

                    span {
                        @include textParam($primary-color, Segoe UI, 18px, 30px, bold);
                    }

                    button {
                        font-size: 16px;
                    }
                }
            }
        }

        .lowerContents {
            padding: 40px 20px 0;

            span {
                @include textParam($primary-color, $lato, 14px, 28px, 800);
                margin-bottom: 14px;
            }

            h1 {
                @include textParam($primary-color, $lato, 30px, 38px, 800);
                margin-bottom: 12px;
            }

            p {
                @include textParam($primary-color, $lato, 16px, 28px, 500);
                margin-bottom: 28px;
            }

            .images {
                grid-template-columns: repeat(3, auto);
                gap: 15px;

                .box {
                    width: 100px;
                    height: 80px;

                    img {
                        margin-top: 20px;
                        width: 30px;
                        height: 22px;
                    }

                    span {
                        @include textParam($primary-color, $lato, 12px, 28px, 700);
                    }
                }
            }

            h2 {
                @include textParam($primary-color, $lato, 18px, 28px, 700);
            }

            strong {
                @include textParam($primary-color, $lato, 22px, 32px, 800);
            }
        }
    }
}